import { defineStore } from 'pinia'

import Payload from '@/lib/Payload'
import { hotReloadStore } from '@/utils/build'

import type { APIPayloadResponse } from '@policyfly/utils/types'

export const useMetaStore = defineStore('meta', {
  state: () => ({
    endorsementMeta: {
      responses: [] as APIPayloadResponse[],
    },
  }),

  getters: {
    endorsementMetaPayload: (state) => Payload.fromResponses(state.endorsementMeta.responses),
  },
})

hotReloadStore(useMetaStore)
