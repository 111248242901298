import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { hotReloadStore } from '@/utils/build'

import type { OtpMethod, ValidateUserResponse } from '@policyfly/protobuf/patrick'

export interface State {
  email: string
  password: string
  rememberMe: boolean
  hasAuthenticator: boolean
  hasSms: boolean
  bingoId: string | null
  /**
   * A path requested before login that should be persisted through the login process and then navigated to when login is complete
   */
  targetPath: string
}

export const useLoginStore = defineStore('login', {
  state: (): State => ({
    email: '',
    password: '',
    rememberMe: false,
    hasAuthenticator: false,
    hasSms: false,
    bingoId: null,
    targetPath: '',
  }),

  actions: {
    /**
     * Validates a user using an email/password combination.
     * If valid will return information about the 2FA devices available.
     */
    async validate ({ email, password, rememberMe }: { email: string, password: string, rememberMe: boolean }): Promise<ValidateUserResponse> {
      const apiStore = useApiStore()
      const validateData = await apiStore.user.validateUser({ email, password })
      this.email = email
      this.password = password
      this.rememberMe = rememberMe
      this.hasAuthenticator = validateData.hasAuthenticator
      this.hasSms = validateData.hasSms
      this.bingoId = validateData.bingoId ?? null
      return validateData
    },
    /**
     * Submits a 2FA code along with the already stored authentication information.
     * If successful will log in the user and store their session details.
     */
    async login ({ token, otpMethod }: { token: string, otpMethod: OtpMethod }): Promise<void> {
      const apiStore = useApiStore()
      const response = await apiStore.user.login({
        email: this.email,
        password: this.password,
        otpToken: token,
        rememberMe: this.rememberMe,
        otpMethod,
      })
      const authenticationStore = useAuthenticationStore()
      authenticationStore.bingoId = response.bingoId ?? null
    },
  },
})

hotReloadStore(useLoginStore)
