import { SPLIT_ON_INDEX_REGEX } from '@policyfly/utils/constants'

import { useApiStore } from '@/stores/api'
import { useSettingsStore } from '@/stores/settings'

import { payloadToResponses } from './responses'

import type { ResponseBlob } from '@/lib/Payload'
import type { ApplicationCategory } from '@policyfly/types/application'
import type { TSFixMe } from '@policyfly/types/common'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { ApplicationKind } from 'types/application'

export type DerivedDataKeys = 'issuance' | 'binder' | 'endorsement' | 'quote'
export type ResponsesObject = { responses: APIPayloadResponse[] }
export interface LoadableApp {
  id?: number
  selectedQuote?: TSFixMe
  parentID?: number | null
  derivedData?: Partial<Record<DerivedDataKeys, ResponsesObject | ResponseBlob>> | null
  category: string
  computedData?: ResponsesObject
  data: ResponsesObject
}

// order matters, the earlier ones take priority because any later duplicate keys will be ignored (including arrays)
const LOADABLE_KEYS: DerivedDataKeys[] = ['issuance', 'binder', 'endorsement', 'quote']

export async function loadParentApplication (policyApplications: LoadableApp[], id: number, policyParentId: number | null | undefined): Promise<never[] | [ResponsesObject]> {
  const app = policyApplications.find((a) => a.id === id)
  // recurse through parents (excluding reinstatements/cancellations) until we find one with data that can be restored
  if (!app || ['REI', 'CAN', 'PCN'].includes(app.category) || !app.selectedQuote) {
    const parentID = app?.parentID
    if (parentID) return loadParentApplication(policyApplications, parentID, policyParentId)
    // if no parent app, loop up the policy parent chain until we find a viable application
    if (policyParentId) {
      try {
        const apiStore = useApiStore()
        const { json: policy } = await apiStore.policy.read(policyParentId)
        // @ts-expect-error(external): Swagger schema is incorrect
        return loadParentApplication(policy.applications, policy.latest_application?.id || 0, policy.parent?.id)
      } catch (err) {
        console.error(err)
        return []
      }
    }
    return []
  }

  const responses = loadParentResponses(app)
  return [{ responses }]
}

/**
 * Recurses up the policy application chain and converts all viable applications into combined responses.
 *
 * Returns a complete list of the combined responses for each application in this policy, most recent first.
 *
 * @param policyApplications
 * @param id The id of the application to start the parent chain
 */
export function loadParentApplicationChain (policyApplications: LoadableApp[], id: number | null): APIPayloadResponse[][] {
  const responses: APIPayloadResponse[][] = []
  let parentID: number | null | undefined = id
  while (parentID) {
    const app = policyApplications.find((a) => a.id === parentID)
    if (app && !['REI', 'CAN', 'PCN'].includes(app.category) && app.selectedQuote) {
      responses.push(loadParentResponses(app, true))
    }
    parentID = app?.parentID
  }
  return responses
}

/**
 * Combines all application form data into a single list of responses.
 *
 * If responses in different forms have the same `k`, then the version from the form later in the workflow takes priority.
 *
 * @param app
 * @param includeAppData If `true` will also include `computedData.responses` within the combined responses
 */
export function loadParentResponses (app: LoadableApp, includeAppData = false): APIPayloadResponse[] {
  const datasets = LOADABLE_KEYS.reduce<APIPayloadResponse[][]>((acc, key) => {
    const data = app.derivedData![key]
    if (data) {
      const responses = (data.responses as APIPayloadResponse[]) || payloadToResponses(data as ResponseBlob)
      if (responses.length) acc.push(responses)
    }
    return acc
  }, [])
  if (includeAppData) {
    const appData = app.computedData?.responses || app.data.responses
    datasets.push(appData)
  }
  const responses = datasets.reduce<APIPayloadResponse[]>((acc, res) => {
    const additions: APIPayloadResponse[] = []
    const testKeys = acc.map((a) => a.k.split(SPLIT_ON_INDEX_REGEX)[0])
    for (const response of res) {
      const searchKey = response.k.split(SPLIT_ON_INDEX_REGEX)[0]
      if (!testKeys.some((k) => k === searchKey)) additions.push(response)
    }
    return acc.concat(additions)
  }, [])
  return responses
}

/**
 * Returns `true` if the provided application is an actionable reinstatement.
 * A reinstatement is only actionable if the current program supports reinstatement workflows.
 */
export function isReinstatement (application: { kind?: ApplicationKind | null, category?: ApplicationCategory | null }): boolean {
  const settingsStore = useSettingsStore()
  return settingsStore.hasReinstatementWorkflow && application.kind === 'ENDORSEMENT' && application.category === 'REI'
}
