import { normalizePath, hashString } from '@policyfly/utils/string'
import { isAPIPayloadResponse } from '@policyfly/utils/type'
import { generateUUID } from '@policyfly/utils/uuid'
// eslint-disable-next-line vue/prefer-import-from-vue
import { pauseTracking, enableTracking } from '@vue/reactivity'
import _set from 'lodash-es/set'
import { reactive, nextTick } from 'vue'

import { WORKING_COPIES_KEY } from '@/constants'

import type { SchemaSettings_Coverage } from '@policyfly/protobuf'
import type { PrimitiveValue, TSFixMe } from '@policyfly/types/common'
import type { APIPayloadResponse } from '@policyfly/utils/types'

export type APIPayloadResponseWithUUID<T extends PrimitiveValue = PrimitiveValue> = APIPayloadResponse<T> & { uuid4: string }
export type APIPayloadPristine = Omit<APIPayloadResponse, 'v'> & { v: undefined }
export interface ResponseBlob {
  [key: string]: ResponseBlob | ResponseBlob[] | PrimitiveValue
}
export interface NestedResponseObject {
  [key: string]: NestedResponseValue
}
export interface ResponseObject {
  [key: string]: ResponseObject | ResponseObject[] | PayloadArray | PayloadResponse | APIPayloadResponse | APIPayloadResponse[] | PrimitiveValue
}
export type NestedResponseValue = APIPayloadResponse | NestedResponseObject | APIPayloadResponse[] | NestedResponseObject[]

interface BasePayloadResponse<T extends PrimitiveValue = PrimitiveValue> {
  v: T
  uuid4?: string
}
export interface PayloadObject {
  [key: string]: PayloadObjectValue
}
type PayloadObjectValue = PayloadResponse | PayloadObject | PayloadObject[] | PayloadArray
export type PayloadValue = PrimitiveValue | PayloadObject | PayloadObject[] | PayloadArray
export interface WatchPath {
  watchPath: string
  deep: boolean
}

function extractNestedBlob (blob: ResponseBlob, payload: Payload, baseKey = ''): Payload {
  for (const [k, v] of Object.entries(blob)) {
    const fullKey = baseKey ? baseKey + k : k
    if (Array.isArray(v)) v.forEach((arrayV, arrayI) => extractNestedBlob(arrayV, payload, `${fullKey}.${arrayI}.`))
    else if (v && typeof v === 'object') extractNestedBlob(v, payload, `${fullKey}.`)
    else payload.createResponse(fullKey, v, false)
  }
  return payload
}

function extractNested (nestedResponseObject: NestedResponseObject, payload: Payload, baseKey = ''): Payload {
  for (const [k, v] of Object.entries(nestedResponseObject)) {
    const fullKey = baseKey ? baseKey + k : k
    if (Array.isArray(v)) {
      v.forEach((arrayV, arrayI) => {
        const indexK = `${fullKey}.${arrayI}`
        if (isAPIPayloadResponse(arrayV)) payload.copyAPIResponse(indexK, arrayV)
        else extractNested(arrayV, payload, `${indexK}.`)
      })
    } else if (isAPIPayloadResponse(v)) payload.copyAPIResponse(fullKey, v)
    else extractNested(v, payload, `${fullKey}.`)
  }
  return payload
}

function extractResponseObject (responseObject: ResponseObject, payload: Payload, baseKey = ''): Payload {
  for (const [k, v] of Object.entries(responseObject)) {
    const fullKey = baseKey ? baseKey + k : k
    if (Array.isArray(v)) {
      v.forEach((arrayV, arrayI) => {
        const indexK = `${fullKey}.${arrayI}`
        if (isAPIPayloadResponse(arrayV)) payload.copyAPIResponse(indexK, arrayV)
        else extractResponseObject(arrayV, payload, `${indexK}.`)
      })
    } else if (v instanceof PayloadArray) payload.copyArray(fullKey, v)
    else if (v instanceof PayloadResponse) payload.copyResponse(fullKey, v)
    else if (isAPIPayloadResponse(v)) payload.copyAPIResponse(fullKey, v)
    else if (v && typeof v === 'object') extractResponseObject(v, payload, `${fullKey}.`)
    else payload.createResponse(fullKey, v, false)
  }
  return payload
}

export interface PayloadLike {
  readonly uuid4: string
  hasKey (path: string): boolean
  hasResponse (path: string): boolean
  get<T extends PayloadValue = PayloadValue> (path: string): T | null
  getWhole<T extends PayloadObjectValue = PayloadObjectValue> (path: string): T | null
  getResponse (path: string): PayloadResponse | null
  getUUID (path: string): string | null | undefined
  getExternalPayload<T extends PayloadLike> (): T | null
  getExternal<T extends PayloadValue = PayloadValue> (path: string): T | null
  set (path: string, value: PrimitiveValue, createMissing?: boolean): void
  untilStable (): Promise<void>
  merge (other?: Payload): Payload
  copy (): Payload
  serialize (baseKey?: string): APIPayloadResponse[]
}

export default class Payload implements PayloadLike {
  private _pristine: boolean
  private _payload: PayloadObject
  private _external: PayloadLike | null
  private _uuid4: string
  public groupIndex: number

  constructor (payload: PayloadObject = {}) {
    this._pristine = true
    this._payload = reactive(payload)
    this._external = null
    this._uuid4 = generateUUID()
    this.groupIndex = 0
  }

  static fromResponses (responses: APIPayloadResponse[], tempOverride = true): Payload {
    pauseTracking()
    const payload = new Payload()
    responses.forEach((response) => {
      payload.copyAPIResponse(normalizePath(response.k), response, tempOverride)
    })
    enableTracking()
    return payload
  }

  static fromResponseBlob (blob: ResponseBlob): Payload {
    pauseTracking()
    const payload = extractNestedBlob(blob, new Payload())
    enableTracking()
    return payload
  }

  static fromNestedResponseObject (nestedResponseObject: NestedResponseObject): Payload {
    pauseTracking()
    const payload = extractNested(nestedResponseObject, new Payload())
    enableTracking()
    return payload
  }

  /**
   * Converts any form of response-like object into a Payload
   *
   * @param responseObject ResponseObject
   * @returns Payload
   */
  static fromResponseObject (responseObject: ResponseObject): Payload {
    pauseTracking()
    const payload = extractResponseObject(responseObject, new Payload())
    enableTracking()
    return payload
  }

  get pristine (): boolean {
    return this._pristine
  }

  get payloadObject (): PayloadObject {
    return this._payload
  }

  get uuid4 (): string {
    return this._uuid4
  }

  /**
   * Creates a "hash" of the payload by stringifying the payload object.
   * Ignores some properties to avoid a circular structure.
   */
  public hash (): number {
    return hashString(JSON.stringify(this._payload, (key, val) => {
      switch (key) {
        case '_defaultItem':
        case '_external':
          return
        default:
          return val
      }
    }))
  }

  /**
   * Returns a promise that resolves when the payload has stopped updating.
   * If 10 ticks pass then this will bail and warn of an infinite loop.
   */
  public async untilStable (): Promise<void> {
    let prevHash = this.hash()
    let iterations = 0
    while (iterations <= 10) {
      await nextTick()
      const newHash = this.hash()
      if (newHash === prevHash) return
      prevHash = newHash
      iterations++
    }
    console.error('Infinite stability loop')
  }

  private _setReactive (path: string, value: TSFixMe, obj = this._payload): void {
    const [curr, ...rest] = path.split('.')
    if (!rest.length) {
      obj[curr] = value
    } else {
      // check if the next path is an array index
      if (/^\d+$/.test(rest[0])) {
        const arr = obj[curr]
        if (arr instanceof PayloadArray) {
          const item = arr.getPayload(+rest[0], true)
          this._setReactive(rest.slice(1).join('.'), value, item)
          return
        } else if (!Array.isArray(arr)) {
          obj[curr] = []
        }
      } else {
        if (!(curr in obj)) {
          obj[curr] = {}
        }
      }
      // @ts-expect-error(fixable): Cannot access prop using curr
      this._setReactive(rest.join('.'), value, obj[curr])
    }
  }

  private _unsetReactive (path: string, obj = this._payload): void {
    const [curr, ...rest] = path.split('.')
    if (!(curr in obj)) return
    if (!rest.length) {
      delete obj[curr]
    } else if (obj[curr] && typeof obj[curr] === 'object') {
      // @ts-expect-error(fixable): Cannot access prop using curr
      this._unsetReactive(rest.join('.'), obj[curr])
    }
  }

  public setExternalPayload (payload: PayloadLike): void {
    this._external = payload
  }

  public getExternalPayload<T extends PayloadLike> (): T | null {
    return this._external as T
  }

  public getExternal<T extends PayloadValue = PayloadValue> (path: string): T | null {
    if (!this._external) return null
    return this._external.get(path)
  }

  public createResponse (path: string, v: PrimitiveValue, temp = false): Payload {
    this._setReactive(path, new PayloadResponse({ v }, temp))
    return this
  }

  public deleteResponse (path: string): void {
    this._unsetReactive(path)
  }

  public loadResponse (path: string, response: APIPayloadResponse | APIPayloadPristine, createMissing = false): void {
    const [, arrayPath = '', arrayIndex = '', arrayFieldPath = ''] = path.match(/^(.+)\.(\d+)\.(.+)$/) || []
    const array = this.get(arrayPath)
    if (array instanceof PayloadArray) {
      array.loadResponse(+arrayIndex, arrayFieldPath, response)
    } else {
      const payloadResponse = this._getInPayload(path)
      if (payloadResponse instanceof PayloadResponse) {
        payloadResponse.load(response)
        if (response.v !== undefined) this._pristine = false
      } else if (createMissing) {
        this._setReactive(path, new PayloadResponse(response, false))
        if (response.v !== undefined) this._pristine = false
      }
    }
  }

  public copyAPIResponse (path: string, response: APIPayloadResponse, temp = false): void {
    this._setReactive(path, new PayloadResponse(response, temp))
  }

  public copyResponse (path: string, response: PayloadResponse): void {
    this._setReactive(path, response.copy())
  }

  private _getInPayload (path: string, useDefaultPayload?: boolean): PayloadObjectValue | null {
    const pathParts = path ? path.split('.') : []
    let current: PayloadObjectValue | null = this._payload
    for (const part of pathParts) {
      if (current instanceof PayloadArray || Array.isArray(current)) {
        if (part === WORKING_COPIES_KEY) {
          current = current[WORKING_COPIES_KEY] ?? null
        } else {
          if (!/^\d+$/.test(part)) return null
          const index = +part
          if (current instanceof PayloadArray) {
            if (useDefaultPayload) {
              // do not create payload copies here as that can cause infinite render loops
              current = {
                ...current.getDefaultPayload(),
                ...(current.getPayload(index) ?? {}),
              }
            } else {
              current = current.getPayload(index)
            }
          } else {
            current = current[index]
          }
        }
      } else if (current instanceof PayloadResponse) {
        return null
      } else {
        current = current[part]
      }
      if (!current) return null
    }
    return current || null
  }

  public hasKey (path: string): boolean {
    return this._getInPayload(path, true) !== null
  }

  public hasResponse (path: string): boolean {
    return this._getInPayload(path, true) instanceof PayloadResponse
  }

  public get<T extends PayloadValue = PayloadValue> (path: string): T | null {
    const value = this._getInPayload(path)
    return (value instanceof PayloadResponse ? value.get() : value) as T | null
  }

  public getWhole<T extends PayloadObjectValue = PayloadObjectValue> (path: string): T | null {
    return this._getInPayload(path) as T | null
  }

  public getResponse (path: string): PayloadResponse | null {
    const value = this._getInPayload(path)
    return value instanceof PayloadResponse ? value : null
  }

  public getUUID (path: string): string | null | undefined {
    const value = this._getInPayload(path)
    return value instanceof PayloadResponse ? value.getUUID() : null
  }

  public set<T extends PrimitiveValue> (path: string, value: T, createMissing = false, temp = false): Payload {
    const payloadResponse = this._getInPayload(path)
    if (payloadResponse instanceof PayloadResponse) payloadResponse.set(value)
    else if (createMissing) this.createResponse(path, value, temp)
    else console.warn(`Could not set path ${path} to ${value}`)
    return this
  }

  public createArray (path: string, defaultItem: Payload): PayloadArray {
    const payloadArray = new PayloadArray(defaultItem)
    this._setReactive(path, payloadArray)
    return payloadArray
  }

  public createMultiArray (coverages: SchemaSettings_Coverage[], path: string, defaultItem: Payload): PayloadArray[] {
    const arrays: PayloadArray[] = []
    for (const coverage of coverages) {
      arrays.push(this.createArray(`${path}.${coverage.label}`, defaultItem))
    }
    return arrays
  }

  public copyArray (path: string, array: PayloadArray): void {
    this._setReactive(path, array.copy())
  }

  public copy (): Payload {
    const copy = new Payload()
    this._copyNested(this._payload, copy, '')
    if (this._external) copy.setExternalPayload(this._external)
    copy.groupIndex = this.groupIndex
    // eslint-disable-next-line no-underscore-dangle
    copy._pristine = this._pristine
    return copy
  }

  private _copyNested (payload: PayloadObject, copy: Payload, baseKey: string): void {
    for (const [k, v] of Object.entries(payload)) {
      const fullKey = baseKey ? baseKey + k : k
      if (v instanceof PayloadResponse) copy.copyResponse(fullKey, v)
      else if (v instanceof PayloadArray) copy.copyArray(fullKey, v)
      else if (Array.isArray(v)) v.forEach((arrayV, arrayI) => this._copyNested(arrayV, copy, `${fullKey}.${arrayI}.`))
      else this._copyNested(v, copy, `${fullKey}.`)
    }
  }

  /**
   * @todo We want to eventually move over to the `mergeDeep` implementation when some of the issues are addressed & deprecate this
   *
   * Shallow merges 2 payloads together and returns a new payload with the combined result.
   * If a top level key exists in both payloads, the other payload's is preferred and will overwrite all nested keys.
   *
   * For a deep merge see {@link mergeDeep}.
   */
  public merge (other: Payload): Payload {
    // eslint-disable-next-line no-underscore-dangle
    const mergedPayloads = Object.assign({}, this._payload, other._payload)
    return new Payload(mergedPayloads)
  }

  /**
   * @todo This is inefficient and causes problems with array reactivity meaning it cannot be used on forms yet, see {@link PF-583}.
   *
   * Recursively merges this payload with the other and returns a new payload with the combined result.
   * If there is a structural difference a console warning appears and the other value takes priority.
   * Arrays and PayloadArrays prefer the other payload's version and will overwrite this payload's version if both exist.
   */
  public mergeDeep (other: Payload): Payload {
    // eslint-disable-next-line no-underscore-dangle
    return this._mergeRecursive(this.copy(), other._payload)
  }

  private _mergeRecursive (payload: Payload, other: PayloadObject, baseKey = ''): Payload {
    Object.entries(other)
      .forEach(([k, v]) => {
        if (baseKey) k = `${baseKey}.${k}`
        if (v instanceof PayloadResponse) {
          if (payload.hasKey(k) && !(payload.getWhole(k) instanceof PayloadResponse)) {
            payload.deleteResponse(k)
            console.warn('Overwriting different type with PayloadResponse', k)
          }
          payload.copyResponse(k, v)
        } else if (v instanceof PayloadArray) {
          if (payload.hasKey(k) && !(payload.getWhole(k) instanceof PayloadArray)) {
            console.warn('Overwriting different type with PayloadResponse', k)
          }
          payload.copyArray(k, v)
        } else if (Array.isArray(v)) {
          if (payload.hasKey(k) && !Array.isArray(payload.getWhole(k))) {
            console.warn('Overwriting different type with Array', k)
          }
          // eslint-disable-next-line no-underscore-dangle
          this._setReactive(k, [], payload._payload)
          v.forEach((obj, i) => {
            const arrayKey = `${k}.${i}`
            this._mergeRecursive(payload, obj, arrayKey)
          })
        } else if (v && typeof v === 'object') {
          if (payload.hasKey(k)) {
            const prev = payload.getWhole(k)
            if (typeof prev !== 'object' ||
              prev instanceof PayloadResponse ||
              prev instanceof PayloadArray ||
              Array.isArray(prev)
            ) {
              payload.deleteResponse(k)
              console.warn('Overwriting different type with Object', k)
            }
          }
          this._mergeRecursive(payload, v, k)
        } else {
          console.warn('Invalid value', v, k) // there's no reason we should get to this step
        }
      })
    return payload
  }

  /**
   * Copies any properties that exist on the current payload from the other payload.
   * This is like a deep Object.assign but only overwriting pre-existing properties.
   * By default only values are copied, UUIDs are not copied from the other payload.
   *
   * **NOTE: UUIDs in PayloadArrays are currently never copied.**
   *
   * @param {Payload} other - The payload to copy properties from
   * @param {boolean} copyUUIDs - Whether to copy uuids too, defaults to false
   * @returns {Payload} The current payload for chaining
   */
  public assign (other: Payload, copyUUIDs = false): Payload {
    // eslint-disable-next-line no-underscore-dangle
    this._assignRecursive(other._payload, copyUUIDs)
    return this
  }

  private _assignRecursive (payload: PayloadObject, copyUUIDs: boolean, baseKey = ''): void {
    Object.entries(payload)
      .forEach(([k, v]) => {
        if (baseKey) k = `${baseKey}.${k}`
        if (v instanceof PayloadResponse) {
          const response = this.getResponse(k)
          if (response && v.get() !== undefined) {
            response.set(v.get())
            if (copyUUIDs) response.setUUID(v.getUUID())
          }
        } else if (v instanceof PayloadArray) {
          if (this.get(k) instanceof PayloadArray) this.copyArray(k, v)
        } else if (Array.isArray(v)) {
          v.forEach((obj, i) => {
            const arrayKey = `${k}.${i}`
            this._assignRecursive(obj, copyUUIDs, arrayKey)
          })
        } else if (v && typeof v === 'object') {
          this._assignRecursive(v, copyUUIDs, k)
        } else {
          console.warn('Invalid value', v, k) // there's no reason we should get to this step
        }
      })
  }

  public serialize (baseKey = ''): APIPayloadResponse[] {
    return this._flattenPayload(this._payload, baseKey)
  }

  private _flattenPayload (payload: PayloadObject, baseKey: string): APIPayloadResponse[] {
    return Object.entries(payload)
      .reduce((acc: APIPayloadResponse[], [k, v]) => {
        k = k.replace(/\./g, '_')

        // construct the actual key for reference
        if (baseKey) k = `${baseKey}_${k}`

        if (v instanceof PayloadResponse) {
          if (!v.isTemp && v.get() !== undefined) acc.push(v.serialize(k))
        } else if (v instanceof PayloadArray) {
          acc.push(...v.serialize(k))
        } else if (Array.isArray(v)) {
          v.forEach((obj, i) => {
            const arrayKey = `${k}_${i}`
            acc.push(...this._flattenPayload(obj, arrayKey))
          })
        } else if (v && typeof v === 'object') {
          acc.push(...this._flattenPayload(v, k))
        } else {
          console.warn('Invalid value', v, k) // there's no reason we should get to this step
        }
        return acc
      }, [])
  }

  public serializeToObject (output: NestedResponseObject = {}, baseKey = ''): NestedResponseObject {
    return this._flattenPayloadToObject(this._payload, output, baseKey)
  }

  private _flattenPayloadToObject (payload: PayloadObject, output: NestedResponseObject, baseKey: string): NestedResponseObject {
    for (const [k, v] of Object.entries(payload)) {
      const fullKey = baseKey ? baseKey + k : k
      if (Array.isArray(v)) v.forEach((arrayV, arrayI) => this._flattenPayloadToObject(arrayV, output, `${fullKey}.${arrayI}.`))
      else if (v instanceof PayloadArray) v.forEach((arrayV, arrayI) => arrayV.serializeToObject(output, `${fullKey}.${arrayI}.`))
      else if (v instanceof PayloadResponse) _set(output, fullKey, v.serialize(fullKey))
      else if (isAPIPayloadResponse(v)) _set(output, fullKey, v)
      else if (v && typeof v === 'object') this._flattenPayloadToObject(v, output, `${fullKey}.`)
    }
    return output
  }

  public removePristineArrayItems (): void {
    this._removePristineRecursive(this._payload)
  }

  private _removePristineRecursive (payload: PayloadObject): void {
    for (const v of Object.values(payload)) {
      if (Array.isArray(v)) {
        v.forEach((val) => this._removePristineRecursive(val))
      } else if (v instanceof PayloadArray) {
        v.removePristine()
        // eslint-disable-next-line no-underscore-dangle
        v.forEach((val) => this._removePristineRecursive(val._payload))
      } else if (v instanceof PayloadResponse || isAPIPayloadResponse(v)) {
        // do nothing
      } else if (v && typeof v === 'object') {
        this._removePristineRecursive(v)
      }
    }
  }
}

export class PayloadArray implements Partial<Omit<Payload[], number | typeof WORKING_COPIES_KEY>> {
  private _defaultItem: Payload
  private _payloadArray: Payload[]
  public [WORKING_COPIES_KEY]: Record<string | number, PayloadObject>

  constructor (defaultItem: Payload, payloadArray: Payload[] = []) {
    this._defaultItem = defaultItem
    this._payloadArray = reactive(payloadArray) as Payload[]
    this[WORKING_COPIES_KEY] = reactive({})
  }

  static fromNestedResponseObjects (source: NestedResponseObject[]): PayloadArray {
    const payloads: Payload[] = []
    let defaultItem: Payload | null = null
    for (let i = 0; i < source.length; i++) {
      const item = source[i]
      if (!defaultItem) {
        defaultItem = new Payload()
        Object.keys(item).forEach((key) => {
          // eslint-disable-next-line no-underscore-dangle
          const temp = key in item && '_temp' in item[key] ? item[key]._temp : false
          defaultItem!.createResponse(key, null, temp as boolean)
        })
      }
      payloads.push(Payload.fromNestedResponseObject(item))
    }
    if (!defaultItem) defaultItem = new Payload()
    return new PayloadArray(defaultItem, payloads)
  }

  // array methods
  public get length (): number {
    return this._payloadArray.length
  }

  public slice (start?: number, end?: number): Payload[] {
    return this._payloadArray.slice(start, end)
  }

  public splice (start: number, deleteCount?: number | undefined): Payload[] {
    return (deleteCount === undefined)
      ? this._payloadArray.splice(start)
      : this._payloadArray.splice(start, deleteCount)
  }

  public forEach (callbackfn: (value: Payload, index: number, array: Payload[]) => void): void {
    return this._payloadArray.forEach(callbackfn)
  }

  public map (callbackfn: (value: Payload, index: number, array: Payload[]) => TSFixMe): TSFixMe[] {
    return this._payloadArray.map(callbackfn)
  }

  public filter (callbackfn: (value: Payload, index: number, array: Payload[]) => TSFixMe): TSFixMe[] {
    return this._payloadArray.filter(callbackfn)
  }

  public some (callbackfn: (value: Payload, index: number, array: Payload[]) => unknown): boolean {
    return this._payloadArray.some(callbackfn)
  }

  public every<S extends Payload> (callbackfn: (value: Payload, index: number, array: Payload[]) => value is S): this is S[] {
    return this._payloadArray.every(callbackfn)
  }

  public push (arg1: Payload): number {
    return this._payloadArray.push(arg1)
  }

  public find (predicate: (value: Payload, index: number, obj: Payload[]) => unknown): Payload | undefined {
    return this._payloadArray.find(predicate)
  }

  public findIndex (predicate: (value: Payload, index: number, obj: Payload[]) => unknown): number {
    return this._payloadArray.findIndex(predicate)
  }

  // custom methods
  private _getIndex (index: number): Payload {
    if (!this._payloadArray[index]) this._payloadArray[index] = this._defaultItem.copy()
    return this._payloadArray[index]
  }

  public loadResponse (index: number, path: string, response: APIPayloadResponse | APIPayloadPristine): void {
    this._getIndex(index).loadResponse(path, response)
  }

  public removePristine (): void {
    this._payloadArray = this._payloadArray.filter((payload) => !payload.pristine)
  }

  /**
   * Adds a new item to the payload array, if a Payload is passed in its values will be "assigned" to the new payload
   * @param {Payload} [payload] A payload to "assign" values from, note that UUIDs will not be copied
   * @returns {Payload} The new payload
   */
  public add (payload?: Payload): Payload {
    const newItem = this._defaultItem.copy()
    if (payload) newItem.assign(payload)
    this._payloadArray.push(newItem)
    return newItem
  }

  public remove (index: number): void {
    this._payloadArray.splice(index, 1)
  }

  public clear (): void {
    this._payloadArray = []
  }

  public getDefault (): Payload {
    return this._defaultItem
  }

  public getDefaultCopy (): Payload {
    return this._defaultItem.copy()
  }

  public getDefaultPayload (): PayloadObject {
    return this._defaultItem.payloadObject
  }

  public getPayloadCopy (index: number): Payload {
    return this._payloadArray[index].copy()
  }

  get (index: number, createMissing: true): Payload
  get (index: number, createMissing?: boolean): Payload | null
  public get (index: number, createMissing = false): Payload | null {
    if (createMissing) {
      return this._getIndex(index)
    } else {
      const payload = this._payloadArray[index]
      return payload instanceof Payload ? payload : null
    }
  }

  getPayload (index: number, createMissing: true): PayloadObject
  getPayload (index: number, createMissing?: boolean): PayloadObject | null
  public getPayload (index: number, createMissing = false): PayloadObject | null {
    const payload = this.get(index, createMissing)
    return payload instanceof Payload ? payload.payloadObject : null
  }

  public set (index: number, path: string, value: PrimitiveValue, createMissing = false, temp = false): void {
    this._getIndex(index).set(path, value, createMissing, temp)
  }

  public setPayload (index: number, payload: Payload): void {
    this._payloadArray[index] = payload
  }

  public copy (): PayloadArray {
    const payloadArray = this._payloadArray.map((payload) => payload.copy())
    return new PayloadArray(this._defaultItem.copy(), payloadArray)
  }

  public serialize (key: string): APIPayloadResponse[] {
    const responses: APIPayloadResponse[] = []
    this._payloadArray.forEach((payload, index) => {
      const baseKey = `${key}_${index}`
      responses.push(...payload.serialize(baseKey))
    })
    return responses
  }
}

export class PayloadResponse<T extends PrimitiveValue = PrimitiveValue> {
  private v: T
  private uuid4?: string
  private _temp: boolean

  constructor (response: BasePayloadResponse<T> | APIPayloadPristine, temp: boolean) {
    const { v = null, uuid4 = undefined } = response || {}
    this.v = v as T
    this.uuid4 = uuid4
    this._temp = temp
  }

  public get isTemp (): boolean {
    return this._temp
  }

  public load (response: APIPayloadResponse<T> | APIPayloadPristine): void {
    const { v, uuid4 } = response
    if (v !== undefined) this.v = v as T
    this.uuid4 = uuid4
  }

  public get (): T | null {
    return this.v
  }

  public getUUID (): string | undefined {
    return this.uuid4
  }

  public set (v: T): void {
    this.v = v
  }

  public setUUID (uuid4: string | undefined): void {
    this.uuid4 = uuid4
  }

  public copy (): PayloadResponse {
    const { v, uuid4, _temp } = this
    return new PayloadResponse({ v, uuid4 }, _temp)
  }

  public serialize (k: string): APIPayloadResponse {
    const { v, uuid4 } = this
    return { k, v, uuid4 }
  }
}

/**
 * Provides a wrapper around multiple payloads so that they can be read or written to.
 * The order of the payloads matters as keys that exist in later payloads take priority, the same as with {@link Payload.merge merging}.
 */
export class PayloadCollection implements PayloadLike {
  public payloads: Payload[]
  private _uuid4: string

  constructor (payloads: PayloadLike[]) {
    this.payloads = []
    for (const p of payloads) {
      this.add(p)
    }
    this._uuid4 = generateUUID()
  }

  get uuid4 (): string {
    return this._uuid4
  }

  /**
   * Adds another Payload or PayloadCollection.
   * Payloads are added to the start of the array in order to prioritise later payloads.
   */
  public add (p: PayloadLike): void {
    if (p instanceof Payload) this.payloads.unshift(p)
    else if (p instanceof PayloadCollection) this.payloads.unshift(...p.payloads)
    else {
      console.warn('Unable to create collection from unknown payload type')
    }
  }

  /**
   * Returns `true` if any payload has this path.
   */
  public hasKey (path: string): boolean {
    return this.payloads.some((p) => p.hasKey(path))
  }

  /**
   * Returns `true` if any payload has this response.
   */
  public hasResponse (path: string): boolean {
    return this.payloads.some((p) => p.hasResponse(path))
  }

  /**
   * Returns the first value found at the provided path in the payloads.
   * If none of the payloads have this path, returns `null`.
   */
  public get<T extends PayloadValue = PayloadValue> (path: string): T | null {
    for (const p of this.payloads) {
      const value = p.get<T>(path)
      if (value !== null) return value
    }
    return null
  }

  /**
   * Returns the first whole value found at the provided path in the payloads.
   * If none of the payloads have this path, returns `null`.
   */
  public getWhole<T extends PayloadObjectValue = PayloadObjectValue> (path: string): T | null {
    for (const p of this.payloads) {
      const value = p.getWhole<T>(path)
      if (value !== null) return value
    }
    return null
  }

  /**
   * Returns the first response found at the provided path in the payloads.
   * If none of the payloads have this path, returns `null`.
   */
  public getResponse (path: string): PayloadResponse | null {
    for (const p of this.payloads) {
      const value = p.getResponse(path)
      if (value !== null) return value
    }
    return null
  }

  /**
   * Returns the first uuid found at the provided path in the payloads.
   * If none of the payloads have this path, returns `null`.
   */
  public getUUID (path: string): string | null | undefined {
    for (const p of this.payloads) {
      const value = p.getUUID(path)
      if (value !== null) return value
    }
    return null
  }

  /**
   * Returns the first value found at the provided path in the external payloads of the provided payloads.
   * If none of the external payloads have this path, returns `null`.
   */
  public getExternal<T extends PayloadValue = PayloadValue> (path: string): T | null {
    for (const p of this.payloads) {
      const value = p.getExternal<T>(path)
      if (value !== null) return value
    }
    return null
  }

  /**
   * Returns the first external payload from the provided payloads.
   * If no external payloads exist, returns `null`.
   */
  public getExternalPayload<T extends PayloadLike> (): T | null {
    for (const p of this.payloads) {
      const value = p.getExternalPayload<T>()
      if (value !== null) return value
    }
    return null
  }

  /**
   * Will set the value to the provided path in the first payload the contains it.
   * If none of the payloads have this path then a warning will be output to the console.
   */
  public set (path: string, value: PrimitiveValue): void {
    for (const p of this.payloads) {
      if (p.hasResponse(path)) {
        p.set(path, value)
        return
      }
    }
    console.warn(`Could not set path ${path} to ${value}`)
  }

  /**
   * Performs a {@link Payload.merge merge} of all the payloads together and returns the merged payload.
   * This does not affect any of the payloads and returns a new Payload not related to them.
   */
  public merge (): Payload {
    return [...this.payloads]
      .reverse()
      .reduce((acc, p) => acc.merge(p), new Payload())
  }

  /**
   * Returns a promise that resolves when all payloads are {@link Payload.untilStable stable}.
   */
  public async untilStable (): Promise<void> {
    await Promise.all(this.payloads.map((p) => p.untilStable()))
  }

  /**
   * Returns a {@link merge merged} payload, which functions as a copy of this collection.
   */
  public copy (): Payload {
    return this.merge()
  }

  /**
   * Performs a {@link merge} and then serializes the merged payload.
   */
  public serialize (baseKey = ''): APIPayloadResponse[] {
    return this.merge().serialize(baseKey)
  }
}
