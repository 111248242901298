import { Date } from '@policyfly/protobuf'
import { dateToString } from '@policyfly/utils/protobuf'

import { useAppContextStore } from '@/stores/appContext'
import { useAuthenticationStore } from '@/stores/authentication'
import { usePolicyStore } from '@/stores/policy'
import { useSettingsStore } from '@/stores/settings'

import { feedEventRouteNameToPolicyRouteName, feedEventRouteNameToApplicationRouteName } from './protobuf'
import { CATEGORY_MAP, DEFAULT_USER, DOCUMENT_TYPE_MAP, DUPLICATE_EVENT, NO_HANDLER, SYSTEM_USER } from '@/components/application/FeedEvent/FeedEventConstants'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import routeNames from '@/router/routeNames'
import { formatDate } from '@/utils/formatter'

import type { EventEntryData } from '@policyfly/protobuf'
import type { FeedEventDescription, FeedEventLog } from 'types/events'

const UNHANDLED_VERBS: string[] = ['generated', 'regenerated', 'debug']

/**
 * Filter out events that cannot be displayed in the feed, because they cannot be
 * handled or they don't have a description or comment to display.
 *
 * Also forces inheritance of the `disabled` property from previous events.
 */
export function filterEvents (events: FeedEventLog[], previousEvents: FeedEventLog[]): FeedEventLog[] {
  const previousEventMap = previousEvents.reduce<Record<number, boolean>>((acc, e) => {
    acc[e.id!] = !!e.disabled
    return acc
  }, {})
  return events.reduce<FeedEventLog[]>((acc, event) => {
    if (!UNHANDLED_VERBS.includes(event.verb || '') && (event.description || event.comment)) {
      event.disabled = !!previousEventMap[event.id!]
      acc.push(event)
    }
    return acc
  }, [])
}

/**
 * Add a description, link and author to each event, if necessary.
 */
export function parseEvents (events: FeedEventLog[]): FeedEventLog[] {
  const settingsStore = useSettingsStore()
  events.forEach((event, index) => {
    try {
      if (settingsStore.protobuf) parseProtobufEvent(event, index)
      else parseLegacyEvent(event, index)
    } catch (err) {
      event.description = null
      console.warn(err)
    }
  })
  return events
}
/**
 * Adds a quote link to the event, links are only displayed while application is being quoted
 */
const quotedEventLink = (status: string | null): FeedEventDescription['link'] => (!isPolicyView() && status === 'QUOTED'
  ? {
      text: 'View Quote',
      click: () => router.push({ name: routeNames.APPLICATION_REVIEW_QUOTES }),
    }
  : undefined
)
/**
 * Adds a link to view the policy document or attachment page.
 */
const attachmentEventLink = (isPolicyDoc: boolean = false, text?: string): FeedEventDescription['link'] => ({
  text: text ?? `View ${isPolicyDoc ? 'Policy Documents' : 'Attachment'}`,
  click: () => {
    const view = isPolicyView() ? 'POLICY' : 'APPLICATION'
    const page = isPolicyDoc ? 'POLICY_DOCS' : 'ATTACHMENTS'
    router.push({ name: routeNames[`${view}_${page}`] })
  },
})

/**
 * Parses the `data` from a mapped event log to generate a feed event for display.
 *
 * The data is mapped from {@link EventEntryData} in {@link eventEntryResponseToFeedEventLog}.
 */
export function parseProtobufEvent (event: FeedEventLog, index: number): void {
  const appContextStore = useAppContextStore()
  const data = event.data as EventEntryData['data']
  const kind = data.oneofKind

  // no event data to parse
  if (kind === undefined) return

  // @ts-expect-error(fixable): protobuf event data is a flat key > string map
  const meta: Record<string, string> = data[kind] ?? {}
  // format meta data protobuf shapes into string representations for display
  for (const [key, value] of Object.entries(meta)) {
    if (Date.is(value)) meta[key] = formatDate(dateToString(value))
  }
  const translationPath = `event.${kind}`

  const text = i18n.global.t(`${translationPath}.text`, meta)
  const link = i18n.global.t(`${translationPath}.link.text`, meta)
  const route = i18n.global.t(`${translationPath}.link.route`, meta)

  // don't display event if we don't have a translation for it
  if (!text || text === `${translationPath}.text`) return
  event.description = { text }

  // include a link if one is provided and the route is valid (page is navigable)
  if (link !== `${translationPath}.link.text` && route) {
    const name = isPolicyView()
      ? feedEventRouteNameToPolicyRouteName(parseInt(route))
      : feedEventRouteNameToApplicationRouteName(parseInt(route))

    // only display link to Application Quotes when in Quoted state
    if (name === routeNames.APPLICATION_REVIEW_QUOTES && appContextStore.status !== 'QUOTED') return

    if (name) {
      event.description.link = {
        text: link,
        click: () => router.push({ name }),
      }
    }
  }

  switch (kind) {
    case 'applicationAutoArchivedEvent':
      event.created_by = SYSTEM_USER
      break
    case 'attachmentVisibilityChangedToInternalOnlyEvent': {
      const { isInternalRole } = useAuthenticationStore()
      if (isInternalRole) event.description.text = i18n.global.t(`event.${kind}Internal.text`, meta)
      break
    }
  }
}

export function parseLegacyEvent (event: FeedEventLog, index: number): void {
  const { verb, data } = event

  const { feedEvent: feedEventSettings, hasCancellationRequests } = useSettingsStore()
  const { status: contextStatus } = useAppContextStore()
  const { isInternalRole } = useAuthenticationStore()
  const { policy_document: policyDocument, original_name: originalName = '', document_type: documentType = '' } = data ?? { }
  const docKind = policyDocument ? 'a policy document' : 'an attachment'
  const docName = documentType
    ? (DOCUMENT_TYPE_MAP[documentType] ?? documentType.charAt(0).toUpperCase() + documentType.slice(1))
    : (originalName ?? 'a document')

  // See if we need to override event author
  if (['renewal-readied', 'expired', 'lock-change', 'sanctions-checked', 'autodecline', 'not-taken'].includes(verb)) {
    event.created_by = SYSTEM_USER
  } else if (!event.created_by || (verb === 'signed' && !data?.autoconfirm)) {
    event.created_by = DEFAULT_USER
  }

  // Comments have an object on them that is handled in the FeedEvent component, we don't need a description or to disable it
  if (verb === 'commented') {
    event.description = null
    return
  }

  event.description = ((): FeedEventDescription | null => {
    const { trigger, modifications = [], response, currentStatus: status } = JSON.parse(JSON.stringify(data || {}))
    // Application, Endorsement or renewal created (trigger is either 'Application' or 'Endorsement' or 'Renewal')
    if (verb === 'created') {
      if (trigger === 'Endorsement (Cancellation)') {
        // Cancellation request programs spawn a cancellation endorsement when requesting cancellation so account for that first
        if (hasCancellationRequests) return { text: 'requested the cancellation of this policy.' }
        // If this isn't the first event, we only really want to show the cancellation event, so hide this one
        if (index !== 0) {
          throw new Error(DUPLICATE_EVENT)
        }
        return { text: 'created a cancellation.' }
      }
      // hide this duplicate event as the reinstate verb handles it
      if (!!feedEventSettings.hasReinstateVerb && trigger === 'Endorsement (Reinstatement)') {
        throw new Error(DUPLICATE_EVENT)
      }
      const name = trigger.toLowerCase()
      return { text: `created a${name !== 'renewal' ? 'n' : ''} ${name}.` }
    }
    // Application or endorsement responses edited
    if (verb === 'modified' && modifications.length === 1 && modifications[0] === 'data') return { text: `edited the ${trigger.toLowerCase()}.` }

    // #region Application Events
    if (['Application', 'Renewal'].includes(trigger)) {
      const applicationType = trigger.toLowerCase()
      const quoteType = trigger === 'Renewal' ? 'renewal quote' : 'quote'
      if (verb === 'modified' && modifications.length === 1) {
        const [modification] = modifications
        if (modification === 'status') {
          switch (status) {
            case 'REVIEW':
              return { text: `submitted the ${applicationType}.` }
            case 'DECLINED':
              return { text: `declined the ${applicationType === 'application' ? '' : applicationType} application.` }
            case 'ISSUED':
              return {
                text: feedEventSettings.issuance?.text ?? 'issued the policy.',
                link: attachmentEventLink(true, feedEventSettings.issuance?.link ?? 'View policy'),
              }
            case 'DRAFT':
              return { text: `chose to take the ${applicationType} status back to draft.` }
            case 'PENDING_QUALITY_CONTROL':
              return { text: 'completed issuance requirements.' }
            case 'PENDING_ISSUE': {
              const text = feedEventSettings.pendingIssue?.text || `finalized the ${applicationType}.`
              const link = feedEventSettings.pendingIssue?.link
              if (link) {
                return {
                  text,
                  link: attachmentEventLink(true, link),
                }
              } else {
                return { text }
              }
            }
          }
        } else if (modification === 'child') {
          throw new Error(DUPLICATE_EVENT)
          // Endorsement created (for the event found on the parent application)
          // return {text: 'created an endorsement.'}
        } else if (modification === 'quote-edit') {
          return {
            text: `edited the ${quoteType}.`,
            link: quotedEventLink(contextStatus),
          }
        } else if (modification === 'archived') {
          return { text: `deleted the ${applicationType}.` }
        } else if (modification === 'derivedData') {
          return { text: 'edited the premium.' }
        } else if (modification === 'license_data') {
          return { text: 'edited the licensing information.' }
        }
      } else if (verb === 'modified' && status === 'PENDING_ISSUE' && ['data', 'status'].every((s) => modifications.includes(s))) {
        return { text: `finalized the ${applicationType}.` }
      } else if (verb === 'modified' && status === 'ISSUED' && ['status', 'quote-edit'].every((s) => modifications.includes(s))) {
        return {
          text: feedEventSettings.issuance?.text ?? 'issued the policy.',
          link: attachmentEventLink(true, 'View policy'),
        }
      } else if (['quote-create', 'status'].every((s) => modifications.includes(s)) || ['quote-edit', 'status'].every((s) => modifications.includes(s))) {
        return status === 'PENDING_ISSUE' || status === 'REQUESTED_TO_BIND'
          ? null
          : {
              text: `added a ${quoteType}.`,
              link: quotedEventLink(contextStatus),
            }
      } else if (['quote-select', 'derivedData', 'effective-date-update'].every((s) => modifications.includes(s))) {
        return { text: `approved a ${quoteType}. The effective date of the policy was updated.` }
      } else if (['quote-select', 'derivedData'].every((s) => modifications.includes(s))) {
        return { text: `approved a ${quoteType}.` }
      } else if (status === 'TERMINATED' && ['status', 'child', 'archived'].every((s) => modifications.includes(s))) {
        return { text: `ended the ${applicationType} with the option to restart a new one.` }
      } else if (['status', 'lost'].every((s) => modifications.includes(s))) {
        return { text: `marked the ${quoteType} as lost.` }
      } else if (['status', 'derivedData'].every((s) => modifications.includes(s))) {
        switch (status) {
          case 'ISSUED':
            return {
              text: 'issued the policy.',
              link: attachmentEventLink(true, 'View policy'),
            }
          case 'PENDING_QUALITY_CONTROL':
            return { text: 'completed issuance requirements.' }
          case 'PENDING_ISSUE':
            return {
              text: 'issued a binder.',
              link: attachmentEventLink(true, 'View binder'),
            }
          case 'DRAFT':
            return { text: `chose to take the ${applicationType} status back to draft.` }
          case 'DECLINED':
            return { text: 'declined the quote.' }
        }
      } else if (verb === 'signature-bypass') {
        return { text: feedEventSettings.signatureBypassText || 'advanced to pending issue.' }
      } else if (verb === 'quality-control') {
        switch (response) {
          case 'PASS':
            return { text: 'marked Quality Control as passed.' }
          case 'FAIL':
            return { text: 'marked Quality Control as failed.' }
          case 'SKIP':
            return { text: 'chose to skip Quality Control.' }
        }
      } else if (verb === 'quality-control-recheck') {
        return { text: 'took the application back to Quality Control status' }
      } else if (verb === 'autodecline') {
        return { text: 'This application has been declined because the TIV is too low.' }
      }
      // #endregion Application Events
      // #region Endorsement events
    } else if (trigger === 'Endorsement') {
      if (verb === 'modified') {
        // Submitted endorsement application
        if (modifications.length === 1 && modifications[0] === 'status' && status === 'REVIEW') {
          return { text: 'submitted an endorsement.' }
          // Endorsement discarded
        } else if ((modifications.length === 1 && modifications[0] === 'archived') ||
            ['data', 'archived'].every((m) => modifications.includes(m))) {
          return { text: 'discarded an endorsement.' }
          // Endorsement declined
        } else if (modifications.length === 1 && modifications[0] === 'status' && status === 'DECLINED') {
          return { text: 'declined the endorsement.' }
          // Endorsement quote submitted
        } else if (['QUOTED', 'PENDING_AUTHORISATION'].includes(status) && ['status', 'quote-create'].every((m) => modifications.includes(m))) {
          return {
            text: 'added an endorsement quote.',
            link: quotedEventLink(contextStatus),
          }
          // Endorsement activated via authorisation (no quote selection)
        } else if (modifications.length === 1 && modifications[0] === 'status' && status === 'ISSUED') {
          const policyStore = usePolicyStore()
          const endorsement = event.application_endorsement_iterator
            ? policyStore.appsByIterator[event.application_endorsement_iterator]
            : null
          return {
            text: 'authorised and activated the endorsement.',
            compare: endorsement
              ? {
                  text: `Endorsement ${event.application_endorsement_iterator}`,
                  base: endorsement.id,
                  compare: endorsement.parentID,
                }
              : undefined,
          }
          // Endorsement activated via quote approval/selection
        } else if (['status', 'quote-select'].every((m) => modifications.includes(m)) ||
            (status === 'ISSUED' && ['status', 'derivedData'].every((m) => modifications.includes(m)))) {
          return status === 'PENDING_AUTHORISATION'
            ? null
            : { text: 'approved the endorsement quote, activating the endorsement.' }
          // Endorsement back to draft
        } else if (status === 'DRAFT' && ['status', 'derivedData'].every((m) => modifications.includes(m))) {
          return { text: 'chose to take the endorsement status back to draft.' }
          // Endorsement quote lost
        } else if (['status', 'lost'].every((s) => modifications.includes(s))) {
          return { text: 'marked the endorsement quote as lost.' }
        }
      }
      // #endregion Endorsement Events
    } else if (trigger === 'Endorsement (Cancellation)') {
      if (status === 'ISSUED' && modifications.includes('status')) {
        return { text: 'cancelled the policy.' }
      }
      if (feedEventSettings.showCancellationArchive && modifications.includes('archived')) {
        return (status === 'PENDING_AUTHORISATION')
          ? { text: 'aborted the scheduled cancellation of this policy.' }
          : { text: 'discarded the request to cancel this policy.' }
      }
    } else if (trigger === 'Endorsement (Reinstatement)') {
      if (status === 'PENDING_AUTHORISATION' && modifications.includes('status')) {
        return { text: 'reinstated this policy.' }
      }
      if (status === 'ISSUED' && modifications.includes('status')) {
        return {
          text: 'A reinstatement document has been generated.',
          link: attachmentEventLink(true, 'View reinstatement.'),
        }
      }
    } else if (trigger === 'Policy') {
      if (verb === 'modified') {
        if (modifications.length === 1 && modifications[0] === 'lost') {
          throw new Error(DUPLICATE_EVENT)
        } else if (modifications.length === 1 && modifications[0] === 'child') {
          return { text: 'submitted an endorsement.' }
        }
      } else if (verb === 'transferred') {
        const { oldOwnerFirstName: oF, oldOwnerLastName: oL, newOwnerFirstName: nF, newOwnerLastName: nL } = data!
        return { text: `transferred ownership from ${oF} ${oL} to ${nF} ${nL}.` }
      }
    } else if (verb === 'signed') {
      const docType = data?.docType.toLowerCase()
      if (docType === 'application') return { text: 'signed an application.' }
      if (docType === 'quote_sheet') return { text: 'signed a quote.' }
      if (docType === 'mtc_quote') return { text: 'signed TRIA Ref 9184.' }
      if (docType === 'mtc_tria_ref_9184') return { text: 'signed TRIA Ref 9184.' }
      if (docType === 'apd_proposal') return { text: 'signed the APD proposal.' }
      if (docType === 'mtc_proposal') return { text: 'signed the MTC proposal.' }
      // If autoconfirm is true, it was a broker/progmin signature. If not, it was a customer signature. These are the same for now, but I'm leaving the distinction in here to make it easier to understand (and change in the future)
      if (data?.autoconfirm === false) {
        return { text: `signed a ${docType}.` }
      } else {
        return { text: `signed a ${docType}.` }
      }
      // Document uploads
      /* eslint-disable camelcase */
    } else if (verb === 'uploaded') {
      if (event.comment) return { text: originalName }
      return {
        text: `uploaded ${docKind}${originalName ? ` (${originalName})` : ' '}.`,
        link: attachmentEventLink(policyDocument, `View ${policyDocument ? 'Policy Documents' : 'Attachments'}`),
      }
    } else if (verb === 'confirmed') {
      // Signature approval status
      if (data?.signatureValid) {
        return { text: "approved a customer's signature." }
      } else {
        return { text: "declined a customer's signature." }
      }
    } else if (verb === 'renewal-readed') {
      return { text: 'The policy is ready for renewal.' }
    } else if (verb === 'expired') {
      return { text: 'The policy has expired.' }
    } else if (verb === 'extension-ended') {
      return { text: `declined the ${CATEGORY_MAP[data?.extensionType]}.` }
    } else if (verb === 'npb-endorsement-pseudo-quote') {
      // This is a unique pseudo event that is generated
      return {
        text: 'added an endorsement quote.',
        link: quotedEventLink(contextStatus),
      }
    } else if (verb === 'renewal-forbidden-toggled') {
      switch (data?.new_forbid_status) {
        case null: return { text: 'has declined renewal of this policy.' }
        case false: return { text: 'has made this policy available for renewal.' }
      }
    } else if (verb === 'sanctions-checked') {
      switch (data?.result) {
        case 'UNLOCKED':
          return { text: 'The sanctions check has passed.' }
        case 'POSITIVE SANCTIONS CHECK':
        case 'FAILED TO PERFORM SANCTIONS CHECK':
          return { text: 'The sanctions check has failed.' }
      }
    } else if (verb === 'lock-change') {
      const { initial_lock_code, new_lock_code } = data!
      if ([1, 2].includes(initial_lock_code) && new_lock_code === null) {
        return { text: 'The failed sanctions check has been overridden.' }
      }
    } else if (verb === 'reinstated') {
      return { text: 'reinstated the policy.' }
    } else if (verb === 'not-taken') {
      switch (data?.substatus) {
        case 'Not Taken Up - Application': return { text: 'This application has been automatically inactivated due to dormancy.' }
          // This status is depreciated, but we still need to handle it for old events
        case 'Not Taken Up - Quote': return { text: 'This application has been automatically inactivated due to the expiration of the quote offer.' }
      }
    } else if (verb === 'attachment-visibility-toggled') {
      const { restricted_visibility } = data!
      if (!restricted_visibility) {
        return {
          text: `changed ${docName} from internal only to global visibility.`,
        }
      } else {
        return isInternalRole
          ? { text: `changed ${docName} from global to internal only visibility.` }
          : { text: `removed ${docName}.` }
      }
    } else if (verb === 'attachment-soft-archived') {
      return { text: `archived ${docKind}${docName ? ` (${docName})` : ''}.` }
    } else if (verb === 'attachment-restored') {
      return { text: `restored ${docKind}${docName ? ` (${docName})` : ''}.` }
    } else if (verb === 'attachment-archived' || verb === 'attachment-destroyed') {
      let text = `removed ${docKind}`
      if (docName) text += ` (${docName})`
      text += '.'
      return { text }
    } else if (verb === 'attachment-renamed') {
      return { text: `changed the name of document ${data?.old_name} to ${data?.new_name}.` }
    } else if (verb === 'partial-decline') {
      return { text: `declined ${data?.declined} coverage with reason ${data?.reason}` }
    } else if (verb === 'partial-cancel') {
      return { text: `cancelled ${data?.cancelled} coverage` }
    } else if (verb === 'assignment-changed') {
      const oldName = data?.old_value ? `${data.old_value.first_name} ${data.old_value.last_name}` : 'Unassigned'
      const newName = data?.new_value ? `${data.new_value.first_name} ${data.new_value.last_name}` : 'Unassigned'
      return { text: `assigned from ${oldName} to ${newName}` }
    } else if (verb === 'inactivated') {
      return { text: 'archived the application.' }
    }
    /* eslint-enable camelcase */
    throw new Error(NO_HANDLER)
  })()
}

export function isPolicyView (): boolean {
  return !!router.currentRoute.value.meta?.policyView
}
