import {
  AttachmentCategory,
  AttachmentStatus,
  AttachmentVisibility,
  VirusScanStatus,
} from '@policyfly/protobuf'
import { timestampToString } from '@policyfly/utils/protobuf'

import { bytesToDataURL } from '@/utils/api'

import type { Attachment } from '@policyfly/protobuf'
import type { SystemAttachmentCategory, UserDefinedAttachmentCategory } from '@policyfly/schema/types/shared/attachmentCategory'
import type { ApplicationAttachment } from 'types/application'

/**
 * @todo Wire up remaining properties
 *
 * Converts an Protobuf Alexandria {@link Attachment} to an equivalent Django {@link ApplicationAttachment} structure.
 * Django fields pointing to file location are not needed in protobuf since we're also provided the file data.
 */
export function attachmentToApplicationAttachment (attachment: Attachment): ApplicationAttachment {
  const unknownUser: ApplicationAttachment['owned_by'] = {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
    domain_program_memberships: [],
  }

  const mimeType = attachment.metadata?.mimeType
  const status = attachmentStatusToApplicationAttachmentPDFStatus(attachment.metadata?.status) ?? 'PASSED'
  const downloadUrl = attachment.data?.length
    ? bytesToDataURL(attachment.data, mimeType)
    : null
  const previewUrl = attachment.previewData?.length
    ? bytesToDataURL(attachment.previewData, 'application/pdf')
    : null

  return {
    applications: attachment.metadata?.applicationIds.map((id) => Number(id)) || [],
    archived: attachment.metadata?.archiveStatus?.archived || false,
    category: attachmentCategoryToSystemAttachmentCategory(attachment.metadata?.category),
    document_type: attachment.metadata?.name.replace(/\.pdf$/i, '') ?? '',
    id: Number(attachment.metadata?.id ?? 0),
    luid: '', // possible legacy code
    created: timestampToString(attachment.metadata?.created, true) ?? '',
    modified: timestampToString(attachment.metadata?.modified, true) ?? '',
    original_name: attachment.metadata?.name || '',
    original_url: downloadUrl ?? '',
    owned_by: unknownUser,
    pdf_download_url: '', // not needed
    pdf_status: (previewUrl || attachment.metadata?.status === AttachmentStatus.ATTACHMENT_STATUS_GENERATING) ? status : null,
    pdf_url: previewUrl,
    policy_document: attachment.metadata?.policyDocument || false,
    resource: '', // only used for files other than pdf & xlsx
    restorable_from_soft_archive: attachment.metadata?.archiveStatus?.restorableFromSoftArchive || false,
    restricted_visibility: attachment.metadata?.visibility === AttachmentVisibility.Visibility_Restricted,
    signature_confirmed: null, // possible legacy code
    signature_confirmed_by: null, // possible legacy code
    signature_status: '', // possible legacy code
    size: Number(attachment.metadata?.sizeBytes ?? 0),
    soft_archived: attachment.metadata?.archiveStatus?.softArchived || false,
    system_generated: attachment.metadata?.systemGenerated || false,
    virus_status: virusScanStatusToString(attachment.metadata?.virusStatus),
    xlsx_download_url: null, // not needed
    xlsx_status: mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? status : null,
    xlsx_url: mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? downloadUrl : null,
    lazy: !downloadUrl,
  }
}

/**
 * Converts Protobuf Alexandria {@link AttachmentCategory} status
 * to equivalent Django {@link SystemAttachmentCategory} or {@link UserDefinedAttachmentCategory} status
 */
export function attachmentCategoryToSystemAttachmentCategory (category: AttachmentCategory | undefined): string {
  return attachmentCategoryToSystemAttachmentCategoryMap.get(category ?? AttachmentCategory.Attachment_Category_Unspecified) || 'XXX'
}

const attachmentCategoryToSystemAttachmentCategoryMap = new Map<AttachmentCategory, SystemAttachmentCategory | UserDefinedAttachmentCategory>([
  [AttachmentCategory.Attachment_Category_Unspecified, 'XXX'],
  [AttachmentCategory.Attachment_Category_Not_Applicable, 'XXX'],
  [AttachmentCategory.Attachment_Category_Inquiry, 'INQ'],
  [AttachmentCategory.Attachment_Category_Application, 'APP'],
  [AttachmentCategory.Attachment_Category_Quote, 'QUO'],
  [AttachmentCategory.Attachment_Category_Endorsement, 'END'],
  [AttachmentCategory.Attachment_Category_Certificate, 'CER'],
  [AttachmentCategory.Attachment_Category_Broker_Invoice, 'INV'],
  [AttachmentCategory.Attachment_Category_Insured_Invoice, 'INI'],
  [AttachmentCategory.Attachment_Category_Declarations, 'DEC'],
  [AttachmentCategory.Attachment_Category_Claim, 'CLA'],
  [AttachmentCategory.Attachment_Category_Addendum, 'ADD'],
  [AttachmentCategory.Attachment_Category_Medical_Certification, 'MED'],
  [AttachmentCategory.Attachment_Category_Temporary_Document, 'TMP'],
  [AttachmentCategory.Attachment_Category_Quote_Summary, 'QSM'],
  [AttachmentCategory.Attachment_Category_Endorsement_Quote_Summary, 'EQS'],
  [AttachmentCategory.Attachment_Category_Binder, 'BND'],
  [AttachmentCategory.Attachment_Category_Order_To_Bind, 'OTB'],
  [AttachmentCategory.Attachment_Category_Confirmation_of_Coverage, 'COC'],
  [AttachmentCategory.Attachment_Category_Loss, 'LOR'],
  [AttachmentCategory.Attachment_Category_APD_Loss, 'APL'],
  [AttachmentCategory.Attachment_Category_MTC_Loss, 'MTL'],
  [AttachmentCategory.Attachment_Category_Drivers_Outside_Criteria, 'DRI'],
])

/**
 * Converts Django {@link SystemAttachmentCategory} or {@link UserDefinedAttachmentCategory} status
 * to equivalent Protobuf Alexandria {@link AttachmentCategory} status.
 */
export function systemAttachmentCategoryToAttachmentCategory (category: SystemAttachmentCategory | UserDefinedAttachmentCategory | '' | undefined): AttachmentCategory {
  return systemAttachmentCategoryToAttachmentCategoryMap.get(category || 'XXX') ?? AttachmentCategory.Attachment_Category_Unspecified
}

const systemAttachmentCategoryToAttachmentCategoryMap = new Map<SystemAttachmentCategory | UserDefinedAttachmentCategory, AttachmentCategory>([
  ['XXX', AttachmentCategory.Attachment_Category_Unspecified],
  ['INQ', AttachmentCategory.Attachment_Category_Inquiry],
  ['APP', AttachmentCategory.Attachment_Category_Application],
  ['QUO', AttachmentCategory.Attachment_Category_Quote],
  ['END', AttachmentCategory.Attachment_Category_Endorsement],
  ['CER', AttachmentCategory.Attachment_Category_Certificate],
  ['INV', AttachmentCategory.Attachment_Category_Broker_Invoice],
  ['INI', AttachmentCategory.Attachment_Category_Insured_Invoice],
  ['DEC', AttachmentCategory.Attachment_Category_Declarations],
  ['CLA', AttachmentCategory.Attachment_Category_Claim],
  ['ADD', AttachmentCategory.Attachment_Category_Addendum],
  ['MED', AttachmentCategory.Attachment_Category_Medical_Certification],
  ['TMP', AttachmentCategory.Attachment_Category_Temporary_Document],
  ['QSM', AttachmentCategory.Attachment_Category_Quote_Summary],
  ['EQS', AttachmentCategory.Attachment_Category_Endorsement_Quote_Summary],
  ['BND', AttachmentCategory.Attachment_Category_Binder],
  ['OTB', AttachmentCategory.Attachment_Category_Order_To_Bind],
  ['COC', AttachmentCategory.Attachment_Category_Confirmation_of_Coverage],
  ['LOR', AttachmentCategory.Attachment_Category_Loss],
  ['APL', AttachmentCategory.Attachment_Category_APD_Loss],
  ['MTL', AttachmentCategory.Attachment_Category_MTC_Loss],
  ['DRI', AttachmentCategory.Attachment_Category_Drivers_Outside_Criteria],
])

/**
 * Convert Protobuf Alexandria @VirusScanStatus to equivalent Django status
 */
export function virusScanStatusToString (status: VirusScanStatus | undefined): string {
  return virusScanStatusToStringMap.get(status ?? VirusScanStatus.Virus_Scan_Status_Unspecified) ?? 'PASSED'
}

const virusScanStatusToStringMap = new Map<VirusScanStatus, string>([
  [VirusScanStatus.Virus_Scan_Status_Failed, 'FAILED'],
  [VirusScanStatus.Virus_Scan_Status_Passed, 'PASSED'],
  [VirusScanStatus.Virus_Scan_Status_Pending, 'PENDING'],
  [VirusScanStatus.Virus_Scan_Status_Unavailable, ''],
  [VirusScanStatus.Virus_Scan_Status_Unspecified, ''],
])

/**
 * Converts Protobuf {@link AttachmentStatus}
 * to equivalent Django {@link ApplicationAttachment.pdf_status}
 */
const attachmentStatusToApplicationAttachmentPDFStatusMap = new Map<AttachmentStatus, ApplicationAttachment['pdf_status']>([
  [AttachmentStatus.ATTACHMENT_STATUS_UNSPECIFIED, 'PASSED'],
  [AttachmentStatus.ATTACHMENT_STATUS_FAILED, 'FAILED'],
  [AttachmentStatus.ATTACHMENT_STATUS_READY, 'PASSED'],
  [AttachmentStatus.ATTACHMENT_STATUS_GENERATING, 'PENDING'],
])

export function attachmentStatusToApplicationAttachmentPDFStatus (status?: AttachmentStatus): ApplicationAttachment['pdf_status'] {
  return attachmentStatusToApplicationAttachmentPDFStatusMap.get(status ?? AttachmentStatus.ATTACHMENT_STATUS_UNSPECIFIED) ?? null
}
