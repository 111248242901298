import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

import type { ApplicationQuote } from 'types/application'

export const useFormEditPremiumStore = defineStore('formEditPremium', {
  state: () => ({
    derivedData: {
      quotes: [] as ApplicationQuote[],
    },
  }),

  getters: {
    derivedDataQuotes: (state) => state.derivedData.quotes,
  },
})

hotReloadStore(useFormEditPremiumStore)
