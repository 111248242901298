import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

const BETA_ENVS = [
  'https://review.policyfly.com',
  'https://risingsun.policyfly.com',
]

export const useGlobalStore = defineStore('global', {
  state: () => ({
    betaOverride: false,
  }),

  getters: {
    isBetaEnv (state) {
      if (state.betaOverride) return true
      if (BETA_ENVS.includes(window?.location?.origin)) {
        return true
      }
      return false
    },
  },
})

hotReloadStore(useGlobalStore)
