<template>
  <div
    id="navbar-header"
    class="bg-navyblue-darken-2 w-full"
  >
    <div
      v-if="routeMeta.isTabsList"
      class="nav-header-tabs"
    >
      <v-tabs
        v-model="currentIndex"
        class="ma-auto pr-4"
        height="55px"
        color="white"
        bg-color="navyblue"
        :class="{ 'wide': isWide }"
      >
        <v-tab
          v-for="(tab, index) of tabs"
          :key="index"
          :ripple="false"
          :class="{ 'active text-white': index === currentIndex, 'text-subheadgrey': index !== currentIndex }"
          class="fs-13 font-bold px-5"
          @click.prevent="onSelectTab(index)"
        >
          {{ tab.label }}
        </v-tab>
        <v-spacer />
        <template v-if="showPolicyTools">
          <UserSelect
            v-if="isProgmin"
            :model-value="owner"
            :loading="ownerLoading"
            :disabled="ownerLoading || !isProgmin"
            confirm="transfer ownership"
            data-test="owner-field"
            label="Owner"
            unassigned-label="Add Owner"
            class="py-2 px-2"
            @update:model-value="onTransfer"
          />
          <UserSelect
            v-if="isProgmin && !routeMeta.policyView"
            key="assignee"
            :model-value="assignee"
            :loading="assigneeLoading"
            :disabled="assigneeLoading || !isProgmin"
            clearable
            label="Assigned to"
            data-test="assignee-field"
            unassigned-label="Add Assignee"
            class="py-2 pl-2"
            @update:model-value="onAssign"
          />
        </template>
      </v-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import UserSelect from '@/components/common/UserSelect/UserSelect.vue'
import { useNotification } from '@/composables/notification'
import { useAppContextStore } from '@/stores/appContext'
import { useApplicationStore } from '@/stores/application'
import { useAttachmentStore } from '@/stores/attachment'
import { useAuthenticationStore } from '@/stores/authentication'
import { useFeedStore } from '@/stores/feed'
import { usePolicyStore } from '@/stores/policy'

import routeNames from '@/router/routeNames'
import { patrickUserToCoreUser } from '@/utils/protobuf'

import type { User } from '@policyfly/protobuf/patrick'
import type { AxiosError } from 'axios'
import type { RouteNamesValues } from 'types/router'

export interface NavbarTab {
  label: string
  link: {
    name: RouteNamesValues
  }
}

export default defineComponent({
  name: 'NavbarHeader',

  components: {
    UserSelect,
  },

  setup () {
    const { snackbar } = useNotification()
    const { t } = useI18n({ useScope: 'global' })

    const applicationStore = useApplicationStore()
    const appContextStore = useAppContextStore()
    const dataLoaded = computed(() => applicationStore.ready)
    const assignee = computed(() => applicationStore.assignee)
    const owner = computed(() => applicationStore.owner)

    const assigneeLoading = ref(false)
    async function onAssign (user: User | null): Promise<void> {
      try {
        assigneeLoading.value = true
        await applicationStore.assign(user || false)
        snackbar.success(`User assignment ${user ? 'changed' : 'removed'} successfully`)
      } catch (err) {
        snackbar.error('Error changing assignment')
      } finally {
        assigneeLoading.value = false
      }
    }

    const route = useRoute()
    const routeMeta = computed(() => route.meta ?? {})

    return {
      routeNames,
      snackbar,

      dataLoaded,
      assignee,
      owner,

      assigneeLoading,
      onAssign,

      appContextStore,

      routeMeta,

      t,
    }
  },

  data () {
    return {
      currentIndex: null as number | null,
      ownerLoading: false,
    }
  },

  computed: {
    ...mapStores(
      usePolicyStore,
      useAttachmentStore,
      useApplicationStore,
      useFeedStore,
    ),
    ...mapState(useAuthenticationStore, {
      isProgmin: 'isProgmin',
    }),
    unconfirmedSignatures (): boolean { return this.attachmentStore.unconfirmedSignatures },
    showPolicyTools (): boolean {
      const { matched } = this.$route
      return this.tabs && matched.some((r) => r.meta.applicationLayout)
    },
    tabs (): NavbarTab[] {
      const route = this.$route
      const baseRoute = route.path.match(/[a-z_]+/)
      if (!baseRoute) return []
      switch (baseRoute[0]) {
        case 'home':
        case 'tasks':
          return [
            { label: 'HOME', link: { name: routeNames.HOME } },
            { label: 'TASKS', link: { name: routeNames.TASKS } },
          ]
        case 'application':
        case 'policy': {
          const policyView = !!route.meta?.policyView
          const tabs: NavbarTab[] = [
            { label: this.t('page.application.tabs.activity'), link: { name: routeNames[policyView ? 'POLICY_FEED' : 'APPLICATION_FEED'] } },
            { label: this.t('page.application.tabs.details'), link: { name: routeNames[policyView ? 'POLICY_REVIEW' : 'APPLICATION_REVIEW'] } },
            { label: this.t('page.application.tabs.attachments'), link: { name: routeNames[policyView ? 'POLICY_ATTACHMENTS' : 'APPLICATION_ATTACHMENTS'] } },
            { label: this.t('page.application.tabs.policy'), link: { name: routeNames[policyView ? 'POLICY_POLICY_DOCS' : 'APPLICATION_POLICY_DOCS'] } },
          ]
          if (!policyView && this.appContextStore.status === 'QUOTED' && !this.unconfirmedSignatures) {
            tabs.push(
              { label: this.t('page.application.tabs.quotes'), link: { name: routeNames.APPLICATION_REVIEW_QUOTES } },
            )
          }
          return tabs
        }
        default:
          return []
      }
    },
    isWide (): boolean {
      switch (this.$route.name) {
        case routeNames.APPLICATION_EDIT:
        case routeNames.APPLICATION_REVIEW:
        case routeNames.POLICY_REVIEW:
        case routeNames.APPLICATION_REVIEW_QUOTES:
        case routeNames.HOME:
        case routeNames.DOCUMENT_DOCUMENTSLIST:
        case routeNames.DOCUMENT_NEW:
        case routeNames.DOCUMENT_REVISIONSLIST:
        case routeNames.DOCUMENT_REVISION_NEW:
        case routeNames.DOCUMENT_REVISION:
        case routeNames.USER_USERSLIST:
        case routeNames.APPLICATION_ATTACHMENTS:
        case routeNames.APPLICATION_POLICY_DOCS:
        case routeNames.POLICY_ATTACHMENTS:
        case routeNames.POLICY_POLICY_DOCS:
        case routeNames.ADMIN_INDEX:
          return true
        default:
          return false
      }
    },
  },

  watch: {
    '$route.name': {
      handler (val: string): void {
        this.setActiveTab(val)
      },
      immediate: true,
    },
    dataLoaded (val: boolean): void {
      if (val) this.setActiveTab(this.$route.name as string)
    },
  },

  methods: {
    onSelectTab (index: number): void {
      const link = this.tabs[index].link
      if (this.$route.name === link.name) return
      this.$router.push(link)
    },
    setActiveTab (val?: string): void {
      this.currentIndex = this.tabs
        ? this.tabs.findIndex((t) => t.link.name === val)
        : null
    },
    async onTransfer (user: User | null): Promise<void> {
      try {
        this.ownerLoading = true
        await this.policyStore.transferPolicy(this.policyStore.id!, user && patrickUserToCoreUser(user))
        if (this.appContextStore.loadedApplicationData.id) {
          await this.applicationStore.fetchApplication(this.appContextStore.loadedApplicationData.id)
        }
        await this.feedStore.list(true)
        this.snackbar.success('Ownership successfully transferred')
      } catch (err) {
        const { status } = (err as AxiosError).response || {}
        if (status === 400) {
          this.snackbar.error('User cannot be an owner of this policy.')
        } else if (status === 403) {
          this.snackbar.error('This user is not associated with the MGA on the application.')
        } else {
          this.snackbar.error('There was an error transferring ownership.')
          console.error(err)
        }
      } finally {
        this.ownerLoading = false
      }
    },
  },
})
</script>
