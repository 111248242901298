import { Big } from 'big.js'
import { displayIfStandard, displayIfExcess, displayIfVehicles, displayIfGrossReceipts, targetGoods, RATING_BASIS_PATH } from 'snippets/bcmtc/common'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'

import Payload, { PayloadArray } from '@/lib/Payload'
import { extractVehicleChanges } from '@/utils/apdHelpers'
import {
  createAddressComputedMap,
  createArrayComputedMap,
  createLocationArrayComputedMap,
  locationArrayFormatter,
} from '@/utils/diffHelpers'
import { checkDisplayIf } from '@/utils/displayIf'
import { formatValue, formatDate } from '@/utils/formatter'
import { getQuoteResponse, getNestedResponse, getLicenseResponse } from '@/utils/responses'

import type { PayloadObject, PayloadResponse } from '@/lib/Payload'
import type { VehicleAppDiff } from '@/stores/diff'
import type { CreateMapFn } from '@/stores/programData'
import type { Condition } from '@policyfly/schema/types/shared/displayIf'
import type { Formatter } from '@policyfly/utils/types/formatter'
import type { DiffNamedDriver, NamedDriversDiff } from 'types/programData/apd'

function hasCommodityType (commodity: string): boolean {
  return checkDisplayIf({ path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: commodity, options: { field: 'type' } } })
}

const STATE_DISCLOSURES = {
  AL: 'REF 9022 - Alabama Surplus Lines Notice',
  AK: 'REF 9025A - Alaska SL and Pre-Printed Notice',
  AZ: 'REF 2097B - Arizona Surplus Lines Notice',
  AR: 'REF 9028 - Arkansas Surplus Lines Notice',
  CA: 'REF 9030 - California Surplus Lines Notice 2',
  CO: 'REF 9031 - Colorado Surplus Lines Notice',
  CT: 'REF 9035A - Connecticut Surplus Lines Notice',
  DE: 'REF 9036 - Delaware Surplus Lines Notice',
  FL: 'Florida Surplus Lines Notice',
  GA: 'REF 9042 - Georgia Surplus Lines Notice',
  HI: 'REF 9044 - Hawaii Surplus Lines Notice',
  ID: 'REF 9045 - Idaho Surplus Lines Notice',
  IL: 'REF 9046 - Illinois Surplus Lines Notice',
  IA: 'REF 9047A - Iowa Surplus Lines Notice',
  KS: 'REF 9048 - Kansas Surplus Lines Notice',
  KY: 'REF 9092 - Kentucky SL Notice',
  LA: 'REF 9049A - Louisiana Surplus Lines Notice',
  ME: 'REF 9050 - Maine Surplus Lines Notice',
  MD: 'REF 9051 - Maryland Surplus Lines Notice',
  MA: 'REF 9054 - Massachusetts Surplus Lines Notice',
  MI: 'REF 9055 - Michigan Surplus Lines Notice',
  MN: 'REF 9056 - Minnesota Surplus Lines Notice',
  MS: 'REF 9057 - Mississippi Surplus Lines Notice',
  MO: 'REF 9058 - Missouri Surplus Lines Notice',
  MT: 'REF 9059 - Montana Surplus Lines Notice',
  NE: 'REF 9060 - Nebraska Surplus Lines Notice',
  NV: 'REF 9061 - Nevada Surplus Lines Notice',
  NH: 'REF 9062 - New Hampshire Surplus Lines Notice',
  NJ: 'REF 9063 - New Jersey Surplus Lines Notice',
  NM: 'REF 9065 - New Mexico Surplus Lines Notice',
  NY: 'REF 9067 - New York Surplus Lines Notice',
  NC: 'REF 9068 - North Carolina SL Notice',
  ND: 'REF 9069 - North Dakota Surplus Lines Notice',
  OH: 'REF 9070 - Ohio Surplus Lines Notice',
  OK: 'REF 9071 - Oklahoma Surplus Lines Notice',
  OR: 'REF 9072 - Oregon Surplus Lines Notice',
  PA: 'REF 9073 - Pennsylvania Surplus Lines Notice',
  PR: 'REF 9074 - Puerto Rico Surplus Lines Notice',
  RI: 'REF 9075 - Rhode Island Surplus Lines Notice',
  SC: 'REF 9076A - South Carolina Surplus Lines Notice',
  SD: 'REF 9077 - South Dakota Surplus Lines Notice',
  TN: 'REF 9078 - Tennessee Surplus Lines Notice',
  TX: 'REF 9079 (amended 2021) - Texas Surplus Lines Notice',
  VI: 'REF 9081A - US Virgin Islands Surplus Lines Notice',
  UT: 'REF 9082 - Utah Surplus Lines Notice',
  VT: 'REF 9083 - Vermont Surplus Lines Notice',
  VA: 'REF 9084 - Virginia Surplus Lines Notice',
  WA: 'REF 9085 - Washington Surplus Lines Notice',
  WV: 'REF 9087 - West Virginia Surplus Lines Notice (Guaranty Act)',
  WI: 'REF 9089 - Wisconsin Surplus Lines Notice',
  WY: 'REF 9090B - Wyoming Surplus Lines Notice',
}

const STATE_EXTRA_DISCLOSURES: Record<string, string> = {
  CA: 'REF 9098B - California Surplus Lines Notice 1 (post bind)',
  TX: 'REF 9080D - Texas Complaints Notice',
}

const driverChanges: { name: string, path: keyof DiffNamedDriver, formatter: Formatter }[] = [
  { name: 'Name', path: 'name', formatter: 'default' },
  { name: 'License Number', path: 'licenseNumber', formatter: 'default' },
  { name: 'State', path: 'state', formatter: 'state' },
  { name: 'Birth Date', path: 'birthDate', formatter: 'date' },
]

function summariseDriver (driver: DiffNamedDriver): string {
  const parts: string[] = []
  parts.push(driver.name)
  parts.push(`License #: ${driver.licenseNumber}`)
  parts.push(formatValue(driver.state, 'state'))
  if (driver.birthDate) parts.push(`DOB: ${formatDate(driver.birthDate, { year: '2-digit' })}`)
  return parts.join('   ')
}

function extractNamedDriverChanges (namedDrivers: NamedDriversDiff | null): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!namedDrivers) return payloadArray
  namedDrivers.added.forEach((vehicle) => {
    const newItem = payloadArray.add()
    newItem.set('state', 'Added')
    newItem.set('summary', summariseDriver(vehicle))
  })
  namedDrivers.changed.forEach((change) => {
    const newSummary = summariseDriver(change.new)
    const changesList: string[] = []
    driverChanges.forEach((c) => {
      const oldVal = formatValue(change.old[c.path], c.formatter) || 'N/A'
      const newVal = formatValue(change.new[c.path], c.formatter) || 'N/A'
      if (oldVal !== newVal) {
        changesList.push(`${c.name}: ${oldVal} to ${newVal}`)
      }
    })
    if (changesList.length > 0) {
      const newItem = payloadArray.add()
      newItem.set('state', 'Changed')
      newItem.set('summary', `${newSummary}  (${changesList.join(', ')})`)
    }
  })
  if (namedDrivers.removed) {
    namedDrivers.removed.forEach((vehicle) => {
      const newItem = payloadArray.add()
      newItem.set('state', 'Removed')
      newItem.set('summary', summariseDriver(vehicle))
    })
  }
  return payloadArray
}

export const createMap: CreateMapFn = () => {
  const deductibleWording = computed<string | null>(() => {
    const appContextStore = useAppContextStore()
    if (['DRAFT', 'REVIEW', 'QUOTED'].includes(appContextStore.status!)) return null
    if (checkDisplayIf(displayIfExcess)) return null
    const standardDeductible = getQuoteResponse('deductible.standard')
    const hasRefrigeratedComType = hasCommodityType('refrigerated')
    const hasAutoHaulerComType = hasCommodityType('autoHaulers')
    const autoHaulerDeductible = getQuoteResponse('deductible.comType.autoHaulers')
    const autoHaulerMinimumDeductible = getQuoteResponse('deductible.comType.autoHaulersMinLoss')
    const hasBoatHaulerComType = hasCommodityType('boatHaulers')
    const hasHomeHaulerComType = hasCommodityType('mobileHome')
    const boatHaulerDeductible = getQuoteResponse('deductible.comType.boatHaulers')
    const mobileHomeDeductible = getQuoteResponse('deductible.comType.mobileHome')
    const doubleWideDeductible = getQuoteResponse('deductible.doubleWide')
    const additionalDrivers = (getQuoteResponse('driversOutsideCriteriaAdditional', true) as { excludeDriver: PayloadResponse, deductible: PayloadResponse, fullName: PayloadResponse }[]) || []
    const additionalShippers = (getQuoteResponse('shippersAdditional', true) as { deductible: PayloadResponse<string>, name: PayloadResponse }[]) || []
    const wording: string[] = []
    if (checkDisplayIf(displayIfStandard)) {
      let prefix = ''
      if (standardDeductible) {
        wording.push(`${formatValue(standardDeductible, 'noDecimalCurrencyUSD')} each and every loss`)
        prefix = 'but '
      }

      if (hasRefrigeratedComType) {
        const refrigeratedDeductible = getQuoteResponse('deductible.comType.refrigerated')
        const refrigeratedOldDeductible = getQuoteResponse('deductible.comType.refrigeratedOldTrailer')
        wording.push(`${prefix}${formatValue(refrigeratedDeductible, 'noDecimalCurrencyUSD')} each and every loss in respect of Refrigeration Breakdown`)
        wording.push(`${prefix}${formatValue(refrigeratedOldDeductible, 'noDecimalCurrencyUSD')} each and every loss in respect of Refrigeration Breakdown for Units aged 10 years old and over`)
      }
      if (hasAutoHaulerComType) {
        wording.push(`${prefix}${formatValue(autoHaulerDeductible, 'noDecimalCurrencyUSD')} each auto hauled each and every loss subject to a minimum ${formatValue(autoHaulerMinimumDeductible, 'noDecimalCurrencyUSD')} each and every loss`)
      }
      if (hasBoatHaulerComType) {
        wording.push(`${prefix}${formatValue(boatHaulerDeductible, 'noDecimalCurrencyUSD')} each boat and every loss`)
      }
      if (hasHomeHaulerComType) {
        wording.push(`${prefix}${formatValue(mobileHomeDeductible, 'noDecimalCurrencyUSD')} each mobile home and every loss`)
      }
      if (checkDisplayIf({ path: 'operations.doubleWideCoverage.response', source: 'responses', permittedValues: [true] })) {
        wording.push(`${prefix}${formatValue(doubleWideDeductible, 'noDecimalCurrencyUSD')} each double-wide auto and every loss`)
      }
    }
    if (additionalDrivers) {
      additionalDrivers.forEach((driver) => {
        // include deductible wording line for every included driver
        if (!driver.excludeDriver.get()) {
          wording.push(`${formatValue(driver.deductible.get(), 'noDecimalCurrencyUSD')} each loss in respect of driver named ${driver.fullName.get()}`)
        }
      })
    }
    if (additionalShippers) {
      additionalShippers.forEach((shipper) => {
        // include deductible wording line for every shipper with one
        const deductible: string = shipper.deductible.get() || '0'
        if (Big(deductible).gt(0)) {
          wording.push(`${formatValue(deductible, 'noDecimalCurrencyUSD')} each loss in respect of ${shipper.name.get()}`)
        }
      })
    }
    if (!wording.length) return null
    return `Deductible:\n${wording.join('\n')}`
  })
  const hasExcludedDrivers = computed(() => {
    const additionalDrivers = (getQuoteResponse('driversOutsideCriteriaAdditional', true) as PayloadObject[]) || []
    if (additionalDrivers) {
      if (additionalDrivers.some((driver) => (driver.excludeDriver as PayloadResponse).get())) return true
    }
    const excludedDrivers = (getNestedResponse('vehicles.excludedDrivers', true) as PayloadObject[]) || []
    if (excludedDrivers) {
      if (excludedDrivers.length) return true
    }
    return false
  })
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'physicalAddress', source: 'responses' }),
    ...createArrayComputedMap('targetGoods', 'operations.targetGoods', 'response', targetGoods.reduce<Record<string, string>>((acc, t) => {
      acc[t.path] = t.label
      return acc
    }, {})),
    ...createLocationArrayComputedMap('terminalLocations', 'coverages.terminalLocations'),
    deductibleWording,
    wordings: computed<{ name: { v: string | null } }[]>(() => {
      const triaResponse = getLicenseResponse('triaResponse') as boolean | null
      const ratingBasis = getQuoteResponse(RATING_BASIS_PATH) as string | null
      const quoteState = getQuoteResponse('sld.state') as keyof typeof STATE_DISCLOSURES & keyof typeof STATE_EXTRA_DISCLOSURES
      // add notice to surplus lines disclosures to be picked up later if premium is < 5k
      const extraDisclosures = { ...STATE_EXTRA_DISCLOSURES }
      const totalPremiumLessTria = getQuoteResponse('totalPremiumLessTria') ?? '0.00'
      if ((typeof totalPremiumLessTria === 'string' && Big(totalPremiumLessTria).lte(Big('5000.00'))) ||
      checkDisplayIf({ path: 'isEndorsement', source: 'store', store: 'appContext', permittedValues: [true] })) {
        extraDisclosures.GA = 'REF 9043 - Georgia Surplus Lines Notice (Standard Disclosure Brochure)'
      }
      const ltlWording = (() => {
        // @ts-expect-error(fixable): The formatter type is stricter than is currently defined in getNestedResponse
        const terminalLocations = locationArrayFormatter(getNestedResponse('coverages.terminalLocations'))
        const limit = getNestedResponse('coverages.terminalLocationsLimit')
        if (!terminalLocations.length) return null
        let wording = 'L.T.L Endorsement (72 Hours Off Truck Cover) as per wording'
        wording += `\nLimit Per Terminal: ${formatValue(limit, 'noDecimalCurrencyUSD')}`
        terminalLocations.forEach((l) => {
          wording += `\nTerminal: ${l.name.v}`
        })
        return wording
      })()
      const displayIfHouseholdGoods: Condition = { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'householdGoods', options: { field: 'type' } } }
      const displayIfMobileHome: Condition = { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'mobileHome', options: { field: 'type' } } }
      const displayIfBoatHaulers: Condition = { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'boatHaulers', options: { field: 'type' } } }
      const gliseWording = (() => {
        if (checkDisplayIf(displayIfExcess)) return null
        let wording = 'GLISE MTC B&C 04/2023'
        if (getNestedResponse('standardPolicyType') === 'Primary') {
          wording += ' is amended to allow excess placement'
        }
        const exclusions: string[] = []
        if (checkDisplayIf(displayIfHouseholdGoods)) {
          exclusions.push('a) (ii) is deleted')
        }
        if (checkDisplayIf(displayIfMobileHome)) {
          exclusions.push('a) iv) is amended by deleting `Mobile Homes`')
        }
        if (checkDisplayIf(displayIfBoatHaulers)) {
          exclusions.push('a) iv) is amended by deleting `Watercraft`')
        }
        if (getNestedResponse('operations.cargoOwned.response')) {
          exclusions.push('g) is deleted')
        }
        if (exclusions.length) {
          wording += ` - Exclusion ${exclusions.join(', exclusion ')}`
        }
        return wording
      })()
      const effectiveDate = getQuoteResponse('effectiveDate')
      let stateDisclosure = STATE_DISCLOSURES[quoteState] || null
      if (effectiveDate && effectiveDate < '2021-07-01' && quoteState === 'WY') {
        stateDisclosure = 'REF 9090A Wyoming Surplus Lines Notice'
      }

      return [
        { name: { v: stateDisclosure || null } },
        { name: { v: extraDisclosures[quoteState] || null } },
        { name: { v: 'REF 9151 - Data Protection Short Form Information Notice' } },
        { name: { v: gliseWording } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'Maintenance of Primary Insurance as per wording' : null } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'Ref 727 - Wording (Amended)' : null } },
        { name: { v: deductibleWording.value } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'refrigerated', options: { field: 'type' } } }]) ? 'Refrigeration Breakdown Endorsement as per wording' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'coverages.contingentCargo.response', source: 'responses', permittedValues: [true] }]) ? 'Contingent Transit Endorsement (Truck Brokering) as per wording' : null } },
        { name: { v: checkDisplayIf(displayIfStandard) ? 'Unattended Truck Endorsement as per wording' : null } },
        { name: { v: checkDisplayIf(displayIfStandard) ? 'Earned Freight Endorsement as per wording' : null } },
        { name: { v: 'Minimum Earned Premium Clause' } },
        { name: { v: checkDisplayIf(displayIfStandard) ? 'Claim Notification Clause' : null } },
        { name: { v: checkDisplayIf(displayIfStandard) ? 'Debris Removal Endorsement as per wording' : null } },
        { name: { v: checkDisplayIf(displayIfStandard) && (['grossReceipts', 'vehicles'] as (string | null)[]).includes(ratingBasis) ? 'Driver Criteria Conditions Endorsement (B&C DCCE 2020/01)' : null } },
        { name: { v: checkDisplayIf({ path: 'refundBonusClause.response', source: 'quote', permittedValues: [true] }) ? 'Refund Bonus Clause' : null } },
        { name: { v: ltlWording } },
        { name: { v: (['grossReceipts', 'vehicles'] as (string | null)[]).includes(ratingBasis) ? 'In Full Premium Endorsement as per wording' : null } },
        { name: { v: ratingBasis === 'namedDrivers' ? 'In Full Premium Endorsement Amended for Named Drivers as per wording' : null } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'In Full Premium Endorsement (Excess)' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'coverages.trailerInterchange.response', source: 'responses', permittedValues: [true] }]) ? 'Trailer Interchange Endorsement as per wording' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'targetGoods', options: { field: 'type' } } }]) ? 'Target Interest Inclusion Endorsement as per wording' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'onHook', options: { field: 'type' } } }]) ? 'On Hook Extension Clause as per wording' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'eggs', options: { field: 'type' } } }]) ? 'Egg Limitation Endorsement as per wording' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'livestockHaulers', options: { field: 'type' } } }]) ? 'Live Animals Inclusion Endorsement as per wording' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'nurseryPlants', options: { field: 'type' } } }]) ? 'Named Perils Only Endorsement as per wording' : null } },
        { name: { v: 'REF464 - War and Civil War Exclusion Clause' } },
        { name: { v: 'REF 2962 - Biological or Chemical Materials Exclusion' } },
        { name: { v: 'REF 2920a - Terrorism Exclusion Endorsement as per wording' } },
        { name: { v: triaResponse === true ? 'REF 5389 - U.S. Terrorism Risk Insurance Act of 2002 as amended New and Renewal Business Endorsement (previously Ref 5218)' : null } },
        { name: { v: triaResponse === false ? 'REF 5390 - U.S. Terrorism Risk Insurance Act of 2002 as amended Not Purchased Clause (previously Ref 5219)' : null } },
        { name: { v: 'REF 9184 - Policyholder Disclosure Notice of Terrorism Insurance Coverage (previously Ref 9104)' } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'REF 1998 - Service of Suit Clause' : null } },
        { name: { v: 'REF 3100 - Sanction Limitation and Exclusion Clause' } },
        { name: { v: 'REF 2342 - Seepage and / or Pollution and / or Contamination Exclusion' } },
        { name: { v: 'REF 5021 - Applicable Law' } },
        { name: { v: 'REF 5401 - Property Cyber and Data Exclusion' } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf({ operator: 'OR', conditions: [{ path: 'state', source: 'responses', permittedValues: ['California'] }, { path: 'physicalAddress.state', source: 'responses', permittedValues: ['California'] }] }) ? 'REF 9191 GLISE -- CCPA Privacy Policy' : null } },
        { name: { v: 'REF 5062 - Fraudulent Claim Clause' } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'REF 1191 - Radioactive Contamination Exclusion Clause - Physical Damage - Direct' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: ['contractors', 'scrap', 'logging'], options: { field: 'type' } } }]) ? 'BC - TR13 Tarpaulin Warranty V1' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, displayIfVehicles, { path: 'leasedTruckWarranty.response', source: 'quote', permittedValues: [true] }]) ? 'Leased Truck Warranty Replacing Scheduled Truck' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.doubleWideCoverage.response', source: 'responses', permittedValues: [true] }]) ? 'Double Wide Endorsement as per wording' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, displayIfHouseholdGoods]) ? 'Household Goods Endorsement' : null } },
        { name: { v: hasExcludedDrivers.value ? 'BC-TR3 Named Driver Exclusion' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, displayIfVehicles, { path: 'operations.namedDrivers', source: 'responses', comparator: { name: 'nonEmptyArray' } }]) ? 'Named Driver Exclusion BC-TR3' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'operations.comType', source: 'responses', comparator: { name: 'includes', value: 'cotton', options: { field: 'type' } } }]) ? 'Cotton Warranty' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, { path: 'additionalNamedInsured', source: 'responses', comparator: { name: 'nonEmptyArray' } }]) ? 'Additional Named Insured Wording' : null } },
        { name: { v: checkDisplayIf({ path: 'premiumPaymentWarranty.response', source: 'quote', permittedValues: [true] }) ? 'Premium Payment Warranty - 60 days BC PPW (07/19)' : null } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'Average / 110% Margin Clause' : null } },
        { name: { v: checkDisplayIf([displayIfStandard, displayIfGrossReceipts]) ? 'Premium Adjustment Clause - V1' : null } },
        { name: { v: checkDisplayIf(displayIfExcess) ? 'REF 1331 - Cancellation Clause' : null } },
      ]
    }),
    activeVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.vehicles)
    }),
    inactiveVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.deletedVehicles)
    }),
    namedDriversDiff: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractNamedDriverChanges(diffStore.appDiffs.namedDriversDiff)
    }),
  }
}
