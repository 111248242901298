import {
  createActiveAPDVehicleScheduleComputed,
  createFirstYearInBusinessComputed,
  createInactiveAPDVehicleScheduleComputed,
} from '@/utils/programData'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    activeAPDVehicleSchedule: createActiveAPDVehicleScheduleComputed(),
    inactiveAPDVehicleSchedule: createInactiveAPDVehicleScheduleComputed(),
    firstYearInBusiness: createFirstYearInBusinessComputed(),
  }
}
