import { defineStore } from 'pinia'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

import type { APIResponse } from 'types/api'
import type { paths } from 'types/swagger'

type DocumentResponse = APIResponse<paths['/api/v1/documents/']['get']>
export type Document = DocumentResponse['results'][number]
type RevisionResponse = APIResponse<paths['/api/v1/documents/{manageddocument_pk}/revisions/{pk}/']['get']>
export type Revision = RevisionResponse['Revision']

export interface SearchParams {
  limit: number
  offset: number
  search?: string
}

export interface DocRevIdPair {
  documentId: number
  revisionId: number
}

export const useDocumentStore = defineStore('document', {
  state: () => ({
    documents: [] as Document[],
    totalDocuments: 0,
    loadedDocument: null as Document | null,
    loadedRevision: null as Revision | null,
  }),

  getters: {
    currentRevision: (state) => state.loadedDocument?.current_revision || null,
  },

  actions: {
    async fetchDocument (id: number) {
      const { data } = await api.documents.read({ path: { pk: id } })
      this.loadedDocument = data.Document
      return data.Document
    },
    async loadDocuments ({ limit, offset, search }: SearchParams) {
      try {
        const { data } = await api.documents.list({ query: { limit, offset, search } })
        this.documents = data.results
        this.totalDocuments = data.count
        return data
      } catch (err) {
        throw new Error(`Error fetching documents.${err}`)
      }
    },
    async loadMoreDocuments ({ limit, offset }: SearchParams) {
      try {
        const { data } = await api.documents.list({ query: { limit, offset } })
        this.documents.push(...data.results)
        this.totalDocuments = data.count
        return data
      } catch (err) {
        throw new Error(`Error fetching documents.${err}`)
      }
    },
    async setVisibility ({ id, restricted_visibility }: { id: number, restricted_visibility: boolean }) {
      try {
        const { data } = await api.documents.patch({ path: { pk: id }, body: { restricted_visibility } })
        const index = this.documents.findIndex((doc) => doc.id === data.Document.id)
        if (index > -1) {
          this.documents.splice(index, 1, data.Document)
        }
        return data.Document
      } catch (err) {
        throw new Error(`Error updating document visibility${err}`)
      }
    },
    async loadRevisions ({ limit, offset, documentId, active }: SearchParams & { documentId: number, active: boolean }) {
      try {
        const { data } = await api.documents.list_revisions({
          path: { manageddocument_pk: documentId },
          // @ts-expect-error(external): `active` is valid, missing in docs
          query: { limit, offset, active },
        })
        return data
      } catch (err) {
        throw new Error(`Error fetching revisions for id:${documentId}.${err}`)
      }
    },
    async fetchRevision ({ documentId, revisionId }: DocRevIdPair) {
      if (!documentId || !revisionId) throw new Error('`documentId` and `revisionId` are required.')
      try {
        const { data } = await api.documents.read_revision({ path: { manageddocument_pk: documentId, pk: revisionId } })
        this.loadedRevision = data.Revision
        return data.Revision
      } catch (err) {
        throw new Error(`Error fetching document: ${documentId}, revision:${revisionId}`)
      }
    },
    async cloneRevision ({ documentId, revisionId, ...payload }: DocRevIdPair & { effective?: string }) {
      // @ts-expect-error(external): effective is erroneously required by docs
      const { data } = await api.documents.clone_revision({ path: { manageddocument_pk: documentId, pk: revisionId }, body: payload })
      return data.Revision
    },
    async deleteRevision ({ documentId, revisionId }: DocRevIdPair) {
      await api.documents.delete_revision({ path: { manageddocument_pk: documentId, pk: revisionId } })
      this.loadedRevision = null
    },
    async updateEffectiveDate (effective: string) {
      if (!this.loadedDocument || !this.loadedRevision) return
      const documentId = this.loadedDocument.id
      const revisionId = this.loadedRevision.id
      const { data } = await api.documents.update_revision({
        path: { manageddocument_pk: documentId!, pk: revisionId! },
        // @ts-expect-error(external): `active` is optional, docs are wrong
        body: { effective },
      })
      this.loadedRevision = data.Revision
      this.loadedDocument = data.Document
      return data.Revision
    },
    async updateActiveState (active: boolean) {
      if (!this.loadedDocument || !this.loadedRevision) return
      const documentId = this.loadedDocument.id
      const revisionId = this.loadedRevision.id
      const { data } = await api.documents.update_revision({
        path: { manageddocument_pk: documentId!, pk: revisionId! },
        // @ts-expect-error(external): `effective` is optional, docs are wrong
        body: { active },
      })
      this.loadedRevision = data.Revision
      this.loadedDocument = data.Document
      return data.Revision
    },
    async finalizeEdits () {
      if (!this.loadedDocument || !this.loadedRevision) return
      const documentId = this.loadedDocument.id
      const revisionId = this.loadedRevision.id
      const { data } = await api.documents.finalize_edits({ path: { manageddocument_pk: documentId!, pk: revisionId! } })
      this.loadedRevision = data.Revision
      return data.Revision
    },
  },
})

hotReloadStore(useDocumentStore)
