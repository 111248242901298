import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

import type { AnyObject } from '@policyfly/types/common'
import type { Application } from 'types/application'

/**
 * Holds information for the BCAPD rating information on the Application.
 *
 * For modern rating use the `rater` composable.
 */
export const useRatingStore = defineStore('rating', {
  state: () => ({
    lastRating: {} as Application['last_rating'] | AnyObject,
  }),

  actions: {
    loadRatingValues (payload: Partial<Application['last_rating']>): void {
      this.lastRating = Object.assign({}, this.lastRating, payload)
    },
  },
})

hotReloadStore(useRatingStore)
