import { defineStore } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'

import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { EditFormSource, EditSchema, EditSection } from 'types/schema/edit'
import type { FormSchema } from 'types/schema/shared/formSchema'

export const useFormEditStore = defineStore('formEdit', {
  state: () => ({
    schema: null as FormSchema<EditFormSource, FormCoverage, EditSection> | null,
    sectionsSlug: '',
    headers: {} as NonNullable<EditSchema['headers']>,
    baseResponses: [] as EditSchema['baseResponses'],
    initialForm: {} as EditSchema['initialForm'],
    banner: null as EditSchema['banner'] | null,
    PNI: '',
  }),

  actions: {
    async loadData () {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (slug === this.sectionsSlug) return Promise.resolve()
      this.$reset()
      try {
        const file = await import(`../../assets/programs/${slug}/edit.ts`)
        const { sections, headers = {}, baseResponses = [], initialForm = {}, banner = null, contextFields } = file.default || file
        this.schema = {
          sections,
          contextFields,
        }
        this.headers = headers
        this.sectionsSlug = slug
        this.baseResponses = baseResponses
        this.initialForm = initialForm
        this.banner = banner
      } catch (err) {
        return handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormEditStore)
