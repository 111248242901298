import { UserProgram } from '@policyfly/protobuf/patrick'

import type { PiniaPlugin } from 'pinia'

type ApiStore = ReturnType<typeof import('@/stores/api')['useApiStore']>
type ApplicationListState = ReturnType<typeof import('@/stores/applicationList')['useApplicationListStore']>['$state']
type AuthenticationState = ReturnType<typeof import('@/stores/authentication')['useAuthenticationStore']>['$state']
type FilterState = ReturnType<typeof import('@/stores/filter')['useFilterStore']>['$state']
type CmsState = ReturnType<typeof import('@/stores/cms')['useCmsStore']>['$state']
type UserState = ReturnType<typeof import('@/stores/user')['useUserStore']>['$state']
export type PersistedState = {
  api: Pick<ApiStore, 'refreshToken' | 'spaToken' | 'spaTokenExpiration'>
  applicationList: Pick<ApplicationListState, 'rowsPerPage' | 'userColumns'>
  authentication: Pick<AuthenticationState, 'userId' | 'programs' | 'programId' | 'bingoId'>
  filter: Pick<FilterState, 'filters' | 'page' | 'sortBy' | 'search'>
  cms: Pick<CmsState, 'lastReadTimestamp'>
  user: Pick<UserState, 'id'>
}

export const VUEX_STORAGE_KEY = 'vuex'
export const PINIA_STORAGE_KEY = 'pinia'

interface LegacyProgram {
  subscription: string
  default_program: boolean
  program: {
    id: number
    name: string
    slug: string
    client: string
  }
}

export function createPersistedStatePlugin (): PiniaPlugin {
  // backwards compatibility for state previously stored with vuex
  const vuexState = JSON.parse(localStorage.getItem(VUEX_STORAGE_KEY) || '{}')
  const piniaState = JSON.parse(localStorage.getItem(PINIA_STORAGE_KEY) || '{}')
  const currentState: PersistedState = {
    api: piniaState.api || vuexState.api || {},
    applicationList: piniaState.applicationList || vuexState.application?.list || {},
    authentication: piniaState.authentication || vuexState.authentication || {},
    filter: piniaState.filter || vuexState.filter || {},
    cms: piniaState.cms || vuexState.cms || {},
    user: piniaState.user || {},
  }

  // save the combined state so any vuex state isn't lost
  localStorage.setItem(PINIA_STORAGE_KEY, JSON.stringify(currentState))

  return ({ store }) => {
    const id = store.$id as keyof PersistedState
    if (!(id in currentState)) return

    // hydrate if a persisted store is being registered
    const prevState = currentState[id] as Partial<typeof store.$state>
    if (prevState) {
      // migrate any previous state
      switch (id) {
        case 'filter':
          if (typeof prevState.sortBy === 'string') {
            prevState.sortBy = [{ key: prevState.sortBy, order: prevState.sortDesc ? 'desc' : 'asc' }]
          }
          break
        case 'authentication':
          if (Array.isArray(prevState.programs) && prevState.programs.length) {
            if ('program' in prevState.programs[0]) {
              prevState.programs = prevState.programs.map<UserProgram>((p: LegacyProgram) => {
                return UserProgram.create({
                  id: p.program.id,
                  defaultProgram: p.default_program,
                  role: p.subscription,
                  client: p.program.client,
                  name: p.program.name,
                  slug: p.program.slug,
                })
              })
            }
          }
      }
      store.$patch(prevState)
    }

    store.$subscribe((event, state) => {
      const storeId = event.storeId as keyof PersistedState
      switch (storeId) {
        case 'api': {
          const apiState = state as ApiStore
          currentState.api = {
            refreshToken: apiState.refreshToken,
            spaToken: apiState.spaToken,
            spaTokenExpiration: apiState.spaTokenExpiration,
          }
          break
        }
        case 'applicationList': {
          const applicationListState = state as ApplicationListState
          currentState.applicationList = {
            rowsPerPage: applicationListState.rowsPerPage,
            userColumns: applicationListState.userColumns,
          }
          break
        }
        case 'authentication': {
          const authenticationState = state as AuthenticationState
          currentState.authentication = {
            userId: authenticationState.userId,
            programs: authenticationState.programs,
            programId: authenticationState.programId,
            bingoId: authenticationState.bingoId,
          }
          break
        }
        case 'cms': {
          const cmsState = state as CmsState
          currentState.cms = { lastReadTimestamp: cmsState.lastReadTimestamp }
          break
        }
        case 'filter': {
          const filterState = state as FilterState
          currentState.filter = {
            filters: filterState.filters,
            page: filterState.page,
            sortBy: filterState.sortBy,
            search: filterState.search,
          }
          break
        }
        case 'user': {
          const userState = state as UserState
          currentState.user = { id: userState.id }
          break
        }
      }
      localStorage.setItem(PINIA_STORAGE_KEY, JSON.stringify(currentState))
    }, { detached: true })
  }
}
