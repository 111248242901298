import { EventIDType } from '@policyfly/protobuf'
import orderBy from 'lodash-es/orderBy'
import uniqBy from 'lodash-es/uniqBy'
import { defineStore } from 'pinia'

import { useNotification } from '@/composables/notification'

import { useApiStore } from './api'
import { useAppContextStore } from './appContext'
import { useSettingsStore } from './settings'
import { hotReloadStore } from '@/utils/build'
import { filterEvents, parseEvents } from '@/utils/feed'

import type { CommentVisibility } from '@policyfly/protobuf'
import type { EventList, FeedEventLog } from 'types/events'

export const useFeedStore = defineStore('feed', {
  state: () => ({
    allApplicationEvents: [] as FeedEventLog[],
    applicationUnreadCommentCount: 0,
    allPolicyEvents: [] as FeedEventLog[],
    policyUnreadCommentCount: 0,
    lastEventCreated: '',
    isLoading: false,
  }),

  getters: {
    applicationEvents: (state) => state.allApplicationEvents.filter((e) => !e.disabled),
    policyEvents: (state) => state.allPolicyEvents.filter((e) => !e.disabled),
  },

  actions: {
    async list (policy: boolean = false) {
      const apiStore = useApiStore()
      const appContext = useAppContextStore()
      const settingsStore = useSettingsStore()
      const { snackbar } = useNotification()
      this.isLoading = true

      try {
        const id = policy ? appContext.policyId : appContext.applicationId
        if (!id) return

        const { json: events } = await apiStore.event.list({
          id: String(appContext.policyId),
          type: !settingsStore.protobuf && !policy ? EventIDType.EventIDType_Application : EventIDType.EventIDType_Policy,
          exclude: 'LuaExecutionEvent',
        })
        const { json: comments } = await apiStore.comment.list(String(appContext.policyId))
        const ordered = orderBy([...comments, ...events], ['created'], ['desc'])

        policy
          ? this.loadPolicyEvents(ordered)
          : this.loadApplicationEvents(ordered)
      } catch (error) {
        console.error('Error loading events:', error)
        snackbar.error('Error loading events')
      } finally {
        this.isLoading = false
      }
    },
    loadApplicationEvents (newEvents: FeedEventLog[]) {
      this.lastEventCreated = newEvents.length
        ? newEvents[0].created
        : ''
      this.allApplicationEvents = filterEvents(parseEvents(newEvents), this.allApplicationEvents)
    },
    loadPolicyEvents (newEvents: FeedEventLog[]) {
      this.allPolicyEvents = filterEvents(parseEvents(newEvents), this.allPolicyEvents)
    },
    async markCommentsRead (payload: { applicationId: string } | { policyId: string }) {
      const apiStore = useApiStore()
      const { json } = await apiStore.comment.markAllRead(payload)

      if ('policyId' in payload) {
        this.policyUnreadCommentCount = 0
        this.loadPolicyEvents(uniqBy([...json, ...this.allPolicyEvents], 'id'))
      } else {
        this.applicationUnreadCommentCount = 0
        this.loadApplicationEvents(uniqBy([...json, ...this.allApplicationEvents], 'id'))
      }
    },
    async markCommentRead (payload: { id: number, kind: EventList }) {
      const apiStore = useApiStore()
      const { id, kind } = payload
      const { json } = await apiStore.comment.markRead(`${id}`)
      this.setEvent({ kind, event: json })
      if (kind === 'application') {
        this.applicationUnreadCommentCount--
      } else {
        this.policyUnreadCommentCount--
      }
    },
    async deleteComment (id: string) {
      const apiStore = useApiStore()
      await apiStore.comment.delete(id)

      this.allApplicationEvents = this.allApplicationEvents.filter((e) => `${e.id}` !== id)
      this.allPolicyEvents = this.allPolicyEvents.filter((e) => `${e.id}` !== id)
    },
    async updateCommentVisibility (id: string, visibility: CommentVisibility, kind: EventList) {
      const apiStore = useApiStore()
      const { json } = await apiStore.comment.updateVisibility(id, visibility)

      this.setEvent({ kind, event: json })
    },
    setEvent ({ kind, event }: { kind: EventList, event: FeedEventLog }) {
      this.updateEvents(kind === 'application' ? this.allApplicationEvents : this.allPolicyEvents, event)
    },
    updateEvents (events: FeedEventLog[], event: FeedEventLog) {
      const index = events.findIndex((e) => e.id === event.id)
      if (index !== -1) {
        events.splice(index, 1, event)
      }
    },
    loadApplicationCommentCount (count: number) {
      this.applicationUnreadCommentCount = count
    },
    loadPolicyCommentCount (count: number) {
      this.policyUnreadCommentCount = count
    },
    addPolicyEvent (payload: FeedEventLog) {
      this.allPolicyEvents.unshift(payload)
    },
    addApplicationEvent (payload: FeedEventLog) {
      this.allApplicationEvents.unshift(payload)
    },
    addGlobalEvent (payload: FeedEventLog) {
      if (this.allPolicyEvents.every((e) => e.id !== payload.id)) this.allPolicyEvents.unshift(payload)
      if (this.allApplicationEvents.every((e) => e.id !== payload.id)) this.allApplicationEvents.unshift(payload)
    },
    disableEvent ({ type, id }: { type: EventList, id: number }) {
      const events = type === 'application'
        ? this.allApplicationEvents
        : this.allPolicyEvents
      const event = events.find((e) => e.id === id)
      if (event) event.disabled = true
    },
  },
})

hotReloadStore(useFeedStore)
