import { defineStore } from 'pinia'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

export const useSignatureStore = defineStore('signature', {
  state: () => ({
    luid: '',
    client_id: '',
    signature_url: '',
    name: '',
    program: '',
  }),

  getters: {
    hasParams: (state) => !!state.luid,
    baseURL: (state) => state.program ? `/${state.program}/api/v1` : '/api/v1',
  },

  actions: {
    async requestSignature (newName?: string): Promise<void> {
      if (newName) this.name = newName
      const { luid, name, baseURL } = this
      const res = await api.attachments.request_signature({ body: { name, luid } }, { baseURL })
      this.signature_url = res.data.sign_url
      this.client_id = res.data.hellosign_client_id
    },
    cancelSignature (): ReturnType<typeof api.attachments.cancel_signature> {
      const { luid, baseURL } = this
      return api.attachments.cancel_signature({ body: { luid } }, { baseURL })
    },
    markSigned (signatureId: string): ReturnType<typeof api.attachments.signed> {
      const { baseURL } = this
      return api.attachments.signed({
        path: { sig_id: signatureId },
        body: { confirmed: false },
      }, { baseURL })
    },
    async fetchAttachment (luid: string) {
      const { baseURL } = this
      const res = await api.attachments.fetch({ path: { luid } }, { baseURL })
      this.luid = luid
      return res.data
    },
  },
})

hotReloadStore(useSignatureStore)
